// Modules
import {useStore} from 'index';


// Assets
import styles from "./s.module.scss";

function Commonalities() {
  const commonalities = useStore((state) => state.commonalities);

  return (
    <div className={styles.container}>
      <h3 className={styles.header}>{commonalities.header}</h3>
      <h4>Common Inclusion Criteria</h4>
      {commonalities.common_inclusion_criteria && <ul>
        {commonalities.common_inclusion_criteria.map((criteria, index) => <li key={index}>{criteria.description}</li>)}
      </ul>}
      <h4>Common Exclusion Criteria</h4>
      {commonalities.common_exclusion_criteria && <ul>
        {commonalities.common_exclusion_criteria.map((criteria, index) => <li key={index}>{criteria.description}</li>)}
      </ul>}
      <h4>Primary Endpoints</h4>
      {commonalities.common_primary_outcomes && <ul>
        {commonalities.common_primary_outcomes.map((criteria, index) => <li key={index}>{criteria.description}</li>)}
      </ul>}
      <h4>Secondary Endpoints</h4>
      {commonalities.common_secondary_outcomes && <ul>
        {commonalities.common_secondary_outcomes.map((criteria, index) => <li key={index}>{criteria.description}</li>)}
      </ul>}
    </div>
);
}

export default Commonalities;
