// Modules
import {useState, useEffect, useCallback, useRef} from "react";
import { Divider, Input, Button, Spin } from 'antd';
import qs from "qs";
import ProtocolTabs from "./ProtocolTabs";
import Grid from '@mui/material/Grid';
import {useStore} from 'index';
import debounce from 'lodash/debounce';

// Assets
import styles from "./s.module.scss";

// API
import { parseQuery, getPhases } from "api";


function Protocol() {
  const tabsRef = useRef(null);
  const [searched, setSearched] = useState(false);
  const [value, setValue] = useState("");
  const [query, setQuery] = useState("Ex: I’m planning for a Phase 3 Diabetic Foot Ulcer in U.S.A?");
  const [queryClassName, setQueryClassName] = useState(styles.query);
  const [loading, setLoading, setTrialFilter, setPhases] = useStore((state) => [state.loading, state.setLoading, state.setTrialFilter, state.setPhases]);
  
  const onSearch = debounce(async () => {
    setLoading(true);
    const queryValue = qs.stringify(
      {
        query: value
      },
      {
        encodeValuesOnly: true,
      }
    );
    const data = await parseQuery(queryValue);
    setTrialFilter(data);
    setSearched(true);
    setQuery("Query: " + value);
    setValue("");
    if (tabsRef.current) tabsRef.current.resetTab();
    // to mimic loading on fast load
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, 300);

  const fetchPhases = useCallback(
    async () => {
    const data = await getPhases();
    setPhases(data);
    },
    [setPhases]
  );

  useEffect(() => {
    fetchPhases();
  }, [fetchPhases])

  useEffect(() => {
    setQueryClassName(styles.query_changed);
    setTimeout(() => {
      setQueryClassName(styles.query);
    }, 2000);
  }, [query])
  
  const onKeyChange = (e) => setValue(e.target.value);
  const onPressEnter = (e) => {
    e.preventDefault();
    onSearch();
  };

  return (
    <Spin tip="Loading" size="large" spinning={loading}>
      <Grid container rowSpacing={1}>
        <Grid item xs={3} className={styles.left_container}>
          <div className={styles.logo}>
          </div>
        </Grid>
        <Grid item xs={9} className={styles.right_container}>
          <h2 className={styles.right_header}>Intelligent Protocol Design (IPD)</h2>
          <Divider />
          <h4 className={queryClassName}>{query}</h4>
          <div className={styles.search_box}>
            <Input.TextArea value={value} className={styles.form} size="large" onPressEnter={onPressEnter} placeholder="Type your protocol query here and press Enter" autoSize onChange={onKeyChange}/>
            <Button className={styles.search_button} onClick={onSearch} disabled={loading}>Start IPD</Button>
          </div>
          {searched && <ProtocolTabs ref={tabsRef}/>}
        </Grid>
      </Grid>
    </Spin>
  );
}

export default Protocol;
