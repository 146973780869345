import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { removeToken, getToken } from "../../helpers";
import { Menu, MenuItem, Popper, ClickAwayListener, Paper, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';

import { useStore } from "index";

// Styles
import Button from "components/Button";

// Styles
import styles from "./s.module.scss";
import Logo from "assets/images/svg/Logo";
import IconArrowDown from "assets/images/svg/IconArrowDown";

const options = [
  { label: "US"},
  { label: "EU"},
];

const AppHeader = () => {
  const theme = useTheme();
  const smallUpSize = useMediaQuery(theme.breakpoints.up('sm'));
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [user, setUser, territory, setTerritory] = useStore((state) => [state.user, state.setUser, state.territory, state.setTerritory]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (index) => {
    setSelectedIndex(index);
    setTerritory(options[index].label);
    handleClose();
  };
  

  const handleLogout = () => {
    setUser(null);
    removeToken();
    navigate("/signin", { replace: true });
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  return (
    <div className={styles.header}>
      <div className={styles["spaced_elements"]}>
        <Logo />
        <div>
          <Button
            className={styles["btn--contained"]}
            variant="outlined"
            onClick={handleClick}
          >
            {smallUpSize && 'Territory:' }{options[selectedIndex].label} <IconArrowDown />
          </Button>
          <Popper id={id} open={open} anchorEl={anchorEl}>
            <ClickAwayListener onClickAway={handleClose}>
              <Paper>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.label}
                      selected={index === selectedIndex}
                      onClick={() => handleMenuItemClick(index)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Menu>
              </Paper>
            </ClickAwayListener>
          </Popper>
      </div>
      </div>
     
      <div className={styles["spaced_elements"]}>
       
        {user?.username ? (
          <>
            <Button
              className={styles["btn--contained"]}
              variant="outlined"
            >
              {smallUpSize ? user?.username: user?.username.charAt(0)}
            </Button>
            <Button
              className={styles["btn--outlined"]}
              type="primary"
              onClick={handleLogout}
            >
              Logout
            </Button>
          </>
        ) : (
          <>
            <Button
              className={styles["btn--contained"]}
              href="/signin"
              type="link"
              variant="contained"
            >
              Login
            </Button>
            <Button
              className={styles["btn--outlined"]}
              href="/signup"
              type="primary"
              variant="outlined"
            >
              SignUp
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default AppHeader;
