import React from 'react';
import { Box } from '@mui/material';
import { Header } from './Header';
import { MainContent } from './Main';
import { Footer } from './Footer';



const LandingPage = () => (
    <Box>
        <Header />
        <MainContent />
    </Box>
);

export default LandingPage;