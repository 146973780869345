// Modules
import cx from 'classnames';
import { useState, useEffect, useRef } from 'react';
import CheckboxBase from '@mui/material/Checkbox';

// Assets
import IconCheckBlue from 'assets/images/svg/IconCheckBlue';
import IconSquareBlue from 'assets/images/svg/IconSquareBlue';
import Loader from 'components/Loader';

import styles from './checkbox.module.scss';

function Checkbox(props) {
  const {
    label,
    checkboxClassName,
    checked,
    onChange,
    defaultChecked,
    disabled,
    icon,
    iconChecked,
    labelClassName,
    // onChange,
    setLoading,
    readOnly,
    name,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  // const [checked, setstate] = useState(initialState);
  const checkboxClassNames = cx(styles.checkbox, {
    [`${styles.checkbox}--checked`]: checked,
    [checkboxClassName]: !!checkboxClassName,
  });

  const labelClassNames = cx(styles.checkbox__label, {
    [labelClassName]: !!labelClassName,
    'checkbox__label--disabled': disabled,
  });

  return (
    <div className={checkboxClassNames}>
      <div className={styles.checkbox__input}>
        {!checked && icon && icon}
        {checked && iconChecked && iconChecked}
        <input
          // className={styles.checkbox__input}
          checked={checked}
          defaultChecked={defaultChecked}
          disabled={disabled}
          name={name}
          onChange={onChange}
          type='checkbox'
          readOnly={readOnly}
        />
      </div>
      {label && <span className={labelClassNames}>{label}</span>}
      {isLoading && <Loader />}
      {/* <CheckboxBase
        {...props}
        sx={{
          color: '#FFC941',
          '&.Mui-checked': {
            color: '#59C49A',
          },
        }}
      /> */}
    </div>
  );
}

Checkbox.propTypes = {};

Checkbox.defaultProps = {};

export default Checkbox;
