
import React from 'react'

export default function Logo() {
  return (
    <svg id="a"
	width={60}
	height={60}
	xmlns="http://www.w3.org/2000/svg" viewBox="0 0 135.01 61.55">
	<path fill="#ffffff" d="M104.24,0c-17,0-30.78,13.78-30.78,30.78s13.78,30.77,30.78,30.77,30.77-13.77,30.77-30.77S121.24,0,104.24,0Zm.6,44.65l-1.89-5.21h-12.6l-1.89,5.21h-5.44l10.67-29.4h5.9l10.69,29.4h-5.44Zm14.75,0h-5.13V15.25h5.13v29.4Zm-27.37-10.33h8.84l-4.43-12.12-4.41,12.12Z"/>
	<g fill="#ffffff">
		<path d="M10.77,45.13c-1.58,0-3.11-.25-4.58-.75-1.47-.5-2.75-1.21-3.85-2.13s-1.88-2.02-2.34-3.3l3.3-1.24c.28,.78,.8,1.47,1.56,2.05s1.66,1.03,2.7,1.35c1.04,.32,2.11,.48,3.21,.48,1.26,0,2.43-.21,3.52-.62,1.08-.41,1.96-.99,2.64-1.73,.67-.74,1.01-1.61,1.01-2.6s-.35-1.86-1.05-2.51c-.7-.65-1.6-1.17-2.69-1.55-1.09-.38-2.23-.68-3.42-.89-2.03-.34-3.85-.82-5.45-1.44-1.6-.62-2.87-1.49-3.8-2.61-.93-1.12-1.4-2.6-1.4-4.43,0-1.71,.49-3.2,1.48-4.47,.99-1.27,2.29-2.26,3.92-2.96,1.62-.7,3.37-1.05,5.25-1.05,1.55,0,3.06,.24,4.53,.72,1.46,.48,2.75,1.19,3.87,2.11,1.12,.92,1.92,2.05,2.42,3.38l-3.34,1.22c-.28-.8-.8-1.49-1.56-2.07-.76-.58-1.66-1.03-2.69-1.34-1.03-.31-2.1-.47-3.22-.47-1.25-.01-2.41,.19-3.49,.61-1.08,.42-1.95,1-2.62,1.74-.67,.74-1.01,1.6-1.01,2.58,0,1.18,.31,2.08,.93,2.7,.62,.62,1.47,1.09,2.55,1.4,1.08,.31,2.29,.58,3.63,.82,1.9,.32,3.67,.83,5.29,1.52s2.93,1.61,3.92,2.76c.99,1.15,1.48,2.56,1.48,4.24s-.49,3.2-1.48,4.47c-.99,1.27-2.29,2.26-3.92,2.96-1.62,.7-3.39,1.05-5.29,1.05Z"/>
		<path d="M27.74,20.17c-.56,0-1.03-.18-1.41-.55-.38-.36-.57-.82-.57-1.36s.19-1,.57-1.37c.38-.36,.84-.55,1.39-.55s1.01,.18,1.39,.55c.38,.36,.57,.82,.57,1.37s-.19,1-.56,1.36c-.37,.36-.83,.55-1.38,.55Zm-1.81,3.42h3.53v21h-3.53V23.59Z"/>
		<path d="M45.38,27.12h-4.77l-.02,17.47h-3.53l.02-17.47h-3.61v-3.53h3.61l-.02-6.59h3.53l.02,6.59h4.77v3.53Z"/>
		<path d="M57.83,45.13c-1.93,0-3.69-.5-5.28-1.49-1.59-.99-2.86-2.33-3.8-4-.95-1.67-1.42-3.52-1.42-5.55,0-1.54,.27-2.98,.82-4.32s1.3-2.51,2.26-3.53,2.08-1.81,3.35-2.38c1.27-.57,2.63-.86,4.07-.86,1.58,0,3.03,.32,4.36,.96,1.32,.64,2.46,1.52,3.4,2.66,.94,1.13,1.65,2.45,2.12,3.95,.47,1.5,.64,3.1,.51,4.81h-17.14c.17,1.19,.56,2.25,1.18,3.18,.62,.93,1.4,1.67,2.36,2.22,.96,.55,2.03,.83,3.2,.84,1.26,0,2.4-.33,3.42-.98,1.02-.65,1.85-1.54,2.48-2.68l3.59,.84c-.84,1.86-2.11,3.38-3.8,4.57-1.69,1.18-3.59,1.77-5.69,1.77Zm-6.85-12.54h13.69c-.11-1.18-.49-2.25-1.13-3.22-.64-.97-1.46-1.75-2.46-2.34-.99-.59-2.08-.88-3.26-.88s-2.25,.29-3.23,.86c-.98,.57-1.79,1.35-2.43,2.32-.64,.97-1.03,2.06-1.19,3.27Z"/>
	</g>
</svg>
  )
}
