// Modules
import ButtonBasic from '@mui/material/Button';
import cx from 'classnames';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

// Styles
import styles from './button.module.scss';

function Button(props) {
  const { children, className, variant, onClick, ...restProps } = props;

  const classNames = cx(styles.button, {
    [styles['button--contained']]: isEqual(variant, 'contained'),
    [styles['button--outlined']]: isEqual(variant, 'outlined'),

    [className]: !!className,
  });

  return (
    <StyledEngineProvider injectFirst>
      <ButtonBasic
        className={classNames}
        onClick={onClick}
        variant={variant}
        {...restProps}
      >
        {children}
      </ButtonBasic>
    </StyledEngineProvider>
  );
}

Button.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
};

Button.defaultProps = {
  variant: 'contained',
};

export default Button;
