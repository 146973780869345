import { queryRegulation, giveFeedback } from 'api';
import styles from './s.module.scss';
import React, { useState, useEffect } from 'react';
import { Spin, message } from 'antd';
import { useStore } from 'index';
import IconThumbsUp from 'assets/images/svg/IconThumbsUp'
import IconThumbsDown from 'assets/images/svg/IconThumbsDown'
import ExpandMoreIcon from 'assets/images/svg/IconChevronDown';
import { 
    Container, 
    Link, 
    Typography, 
    Box, 
    Grid, 
    TextField, 
    Button,
    IconButton,
    Card, CardHeader, CardContent, CardActions, Collapse
} from '@mui/material';
import { styled } from '@mui/material/styles';

const reactions = {
    LIKE: "LIKE",
    DISLIKE: "DISLIKE"
}

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

const RegulationBot = () => {
    const [loading, setLoading, territory, user] = useStore((state) => [state.loading, state.setLoading, state.territory, state.user]);
    const [queryClassName, setQueryClassName] = useState(styles.query);
    const [submittedQuery, setSubmittedQuery] = useState(null);
    const [submittingQuery, setSubmittingQuery] = useState(false);
    const [query, setQuery] = useState('');
    const [answer, setAnswer] = useState('');
    const [sources, setSources] = useState([]);
    const [feedback, setFeedback] = useState(null);
    const [expanded, setExpanded] = React.useState(true);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };


    const handleInputChange = (event) => {
        setQuery(event.target.value);
    };

    const handleFeedback = async (reaction) => {
        try {
            const feedback = await giveFeedback(user, submittedQuery, answer, reaction);
            setFeedback(feedback?.attributes);
        } catch (error) {
            message.error("Something got wrong, please try again later");
        }
    }

    const handleThumbsUp = (event) => {
        if (feedback) {
            return;
        }
        handleFeedback(reactions.LIKE);
    }

    const handleThumbsDown = (event) => {
        if (feedback) {
            return;
        }
        
        handleFeedback(reactions.DISLIKE)
    }

    const handleEnter = (event) => {
        
        if (event.key != 'Enter') {
            return;
        }
        event.preventDefault();
        handleSubmit(event);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!query) {
            message.error("Please type your question.");
            return;
        }
        setSubmittingQuery(true);
        setLoading(true);
        const apiAnswer = await queryRegulation(query, territory);
        setAnswer(apiAnswer.answer);
        setSources(apiAnswer.sources);
        setSubmittedQuery(query);
        setQuery("");
        setSubmittingQuery(false);
        setFeedback(null);
        // to mimic loading on fast load
        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    useEffect(() => {
        setQueryClassName(styles.query_changed);
        setTimeout(() => {
            setQueryClassName(styles.query);
        }, 2000);
    }, [submittingQuery]);

    return (
        <Spin tip="Loading" size="large" spinning={loading}>
            <Container maxWidth={"lg"}>
                <Box mt={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', minHeight: '90vh'}}>
                    <Grid container textAlign={"center"} alignItems="center" justifyContent="center">
                        <Grid item xs={12} md={9}>
                            <img src="/images/northstar.png" alt="Logo" height="100" width="170" />
                            {submittedQuery !== null && <Typography variant="body1" className={queryClassName}>Query: {submittedQuery}</Typography>}
                            <TextField 
                            fullWidth
                            size="small" 
                            margin='normal' 
                            placeholder='Enter your query' 
                            value={query} 
                            onChange={handleInputChange}
                            onKeyUp={handleEnter} />
                            <Button sx={{ width: '150px' }} variant="contained" color="primary" fullWidth={false} onClick={handleSubmit}>Submit</Button>
                        </Grid>
                    </Grid>
                    <Grid container mt={2} sx={{ flexGrow: 1 }}>
                    
                        <Grid item xs={12} md={12}>
                        { answer && <Card mt={2} elevation={0} sx={{ background: '#f8f8f8' }}>
                            <CardHeader
                                title="Answer"
                            />
                            <CardContent >
                                <Typography variant="body2" paragraph>
                                    {answer}
                                </Typography>
                            </CardContent>
                            <CardActions disableSpacing>
                                
                                <IconButton aria-label="Like" sx={{ color: (feedback && feedback.Reaction == reactions.LIKE) ? "blue" : "black" }} variant="contained" disabled={(feedback && feedback.Reaction != reactions.LIKE)}>
                                    <IconThumbsUp onClick={(handleThumbsUp)} />
                                </IconButton>
                            
                            
                                <IconButton aria-label="Dislike" sx={{ color: (feedback && feedback.Reaction == reactions.DISLIKE) ? "red" : "black" }} disabled={(feedback && feedback.Reaction != reactions.DISLIKE)}>
                                    <IconThumbsDown onClick={(handleThumbsDown)} />
                                </IconButton>
                           
                               
                                <ExpandMore
                                    expand={expanded}
                                    onClick={handleExpandClick}
                                    aria-expanded={expanded}
                                    aria-label="Show Sources"
                                >
                                <ExpandMoreIcon />
                                </ExpandMore>
                            </CardActions>
                            <Collapse in={expanded} timeout="auto" unmountOnExit>
                                <Card mt={2} elevation={0} sx={{ background: '#f8f8f8' }}>
                                <CardHeader
                                    title="Sources"
                                />
                                <CardContent>
                                    {sources.length > 0 && (
                                    <ul>
                                        {sources.map((source, index) => (
                                            <li key={index}>
                                                <Typography variant="body2" paragraph>Source Content: {source.page_content}</Typography>
                                                <Typography variant="body2" paragraph>Citation/Title: {source.metadata.title}</Typography>
                                                <Typography variant="body2" paragraph>Link: <Link href={source.metadata.link} target="_blank" rel="noreferrer">{source.metadata.link}</Link></Typography>
                                            </li>
                                        ))}
                                    </ul>) }

                                   
                                </CardContent>
                                </Card>
                            </Collapse>
                            </Card> 
                            }
                        </Grid>
                    </Grid>
                   
                    
                    <Typography variant='body2' mt={2}>
                        For feedback and questions, you can reach us at <Link href="mailto:support@siteai.io">support@siteai.io</Link>
                    </Typography>
                    
                    
            </Box>

            
            
            </Container>
            
        </Spin>
    );
};

export default RegulationBot;
