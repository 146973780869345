// Modules
import { useState } from 'react';

// Context
import { RecommendedActionsContext } from 'context/RecommendedActionsContext';

const RecommendedActionsProvider = RecommendedActionsContext.Provider;

function RecommendedActionsProviderComponent(props) {
  const { children } = props;

  const [recommendedActions, setRecommendedActions] = useState([]);
  const [pagination, setPagination] = useState(10);

  const value = {
    recommendedActions,
    pagination,
    setRecommendedActions,
    setPagination,
  };
  return (
    <RecommendedActionsProvider value={value}>
      {children}
    </RecommendedActionsProvider>
  );
}

RecommendedActionsProviderComponent.propTypes = {};

RecommendedActionsProviderComponent.defaultProps = {};

export default RecommendedActionsProviderComponent;
