// Modules
import { useEffect, useState, useCallback, useMemo } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table";
import qs from "qs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import filter from "lodash/filter";

// API
import { loadSites } from "api";

// Assets
import IconCheckGreen from "assets/images/svg/IconCheckGreen";
import IconNotActive from "assets/images/svg/IconNotActive";

// Components
import Button from "components/Button";
import Loader from "components/Loader";
import Input from "components/Input";
import CheckboxNonActivated from "components/CheckboxNonActivated";

// Context
import { useSiteSelectionContext } from "context/SiteSelectionContext";

// Constants
import { SITES_LABELS } from "constants/sites";

// Helpers
import { calculateCostIncrease, calculateProbofCompleting } from "helpers";
import { formatSitesData } from "helpers";

// Styles
import styles from "./s.module.scss";
const columnHelper = createColumnHelper();

function Dashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const { pagination, setSites, sites, addedSites, setAddedSites } =
    useSiteSelectionContext();

  const sitesCount = useMemo(
    () => Object.values(filter(addedSites, (site) => site === true)).length,
    [addedSites]
  );

  const handleSiteChange = useCallback(
    (row, e) => {
      if (
        !!addedSites[row?.original?.id] &&
        Object.values(addedSites || {}).length <= 15
      )
        return;
      setAddedSites((prevChecked) => ({
        ...prevChecked,
        [row?.original?.id]: !addedSites[row?.original?.id],
      }));
    },
    [addedSites, setAddedSites]
  );

  const columns = [
    columnHelper.accessor("siteRank", {
      cell: ({ row, getValue }) => (
        <div className={styles["col--name"]}>
          <div className={styles["dashboard-table__cell--icon"]}>
            <CheckboxNonActivated
              checked={addedSites[row?.original?.id]}
              icon={<IconNotActive />}
              iconChecked={<IconCheckGreen />}
              onChange={() => handleSiteChange(row)}
            />
          </div>
          <span className={styles["dashboard-table__cell--name"]}>
            {getValue()}
          </span>
        </div>
      ),
      header: SITES_LABELS.siteRank,
    }),
    columnHelper.accessor("siteScore", {
      header: SITES_LABELS.siteScore,
    }),
    columnHelper.accessor("name", {
      header: SITES_LABELS.name,
    }),
    columnHelper.accessor("qualifiedPatients", {
      header: SITES_LABELS.qualifiedPatients,
    }),
    columnHelper.accessor("estimatedRandomizations", {
      header: SITES_LABELS.estimatedRandomizations,
    }),

    columnHelper.accessor("pastStudies", {
      header: SITES_LABELS.pastStudies,
    }),
    columnHelper.accessor("PIExperience", {
      header: SITES_LABELS.PIExperience,
    }),
  ];

  const table = useReactTable({
    data: sites,
    columns,
    pageSize: 15,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: { pageSize: 15 },
    defaultPageSize: 15,
  });

  const query = qs.stringify(
    {
      pagination: {
        start: 0,
        limit: pagination,
      },
      sort: ["siteRank:asc"],
    },
    {
      encodeValuesOnly: true,
    }
  );

  const fetchSites = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await loadSites(query);

      if (data?.error) {
        throw data?.error;
      } else {
        const sites = formatSitesData(data?.data);
        setSites(sites);

        const defaultSelectedSites = sites.slice(0, 15);
        const activatedObj = defaultSelectedSites?.reduce((acc, item) => {
          acc[item.id] = true;
          return acc;
        }, {});
        setAddedSites(activatedObj);

        // set the token
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [query, setSites, setAddedSites]);

  useEffect(() => {
    fetchSites();
  }, [fetchSites]);

  useEffect(() => {
    table.setPageSize(pagination);
  }, []);

  return (
    <div className={styles.dashboard}>
      <div className={styles.container}>
        <h2 className={styles.dashboard__title}>Site Selection Dashboard</h2>
        <table className={styles["dashboard-table"]}>
          <thead className={styles["dashboard-table__header"]}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    style={{ width: header.getSize() }}
                    className={styles["dashboard-table__header__cell"]}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? "cursor-pointer select-none"
                            : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {/* {{
                          asc: " 🔼",
                          desc: " 🔽",
                        }[header.column.getIsSorted()] ?? null} */}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className={styles["dashboard-table__body"]}>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {isLoading && <Loader withOverlay />}
        <div className={styles["dashboard-table__actions"]}>
          <Grid
            container
            className={styles.dashboard__col}
            alignItems="center"
            columnGap="20px"
          >
            <Typography component="p" textAlign="center">
              Site Count : {sitesCount}
            </Typography>
          </Grid>
          <Grid
            container
            className={styles.dashboard__col}
            alignItems="center"
            flexDirection="column"
            rowGap="10px"
          >
            <Typography component="p" textAlign="center">
              Cost increase, %
            </Typography>
            <Input
              className={styles.actions__input}
              value={`${calculateCostIncrease(sitesCount)}`}
              readOnly
            />
          </Grid>
          <Grid
            container
            className={styles.dashboard__col}
            alignItems="center"
            flexDirection="column"
            rowGap="10px"
          >
            <Typography component="p">
              Prob. of completing within schedule, %
            </Typography>
            <Input
              className={styles.actions__input}
              value={`${calculateProbofCompleting(sitesCount)}`}
              readOnly
            />
          </Grid>
        </div>
        <div className={styles["dashboard__btn-container"]}>
          <Button
            // className={styles.dashboard__btn}
            onClick={fetchSites}
            variant="outlined"
          >
            Re-Run
          </Button>
          <Link to="/dashboard-activation" className={styles.dashboard__btn}>
            Next
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
