// Modules
import cx from "classnames";

// Assets
import IconCheckBlue from 'assets/images/svg/IconCheckBlue';
import IconSquareBlue from "assets/images/svg/IconSquareBlue";

import styles from './checkbox.module.scss';

function Checkbox(props) {
  const {
    label,
    checkboxClassName,
    checked,
    onChange,
    defaultChecked,
    disabled,
    labelClassName,
    // onChange,

    name,
    readOnly,
  } = props;
  // const [checked, setstate] = useState(initialState);
  const checkboxClassNames = cx(styles.checkbox, {
    [checkboxClassName]: !!checkboxClassName,
  });
  const labelClassNames = cx(styles.checkbox__label, {
    [labelClassName]: !!labelClassName,
    "checkbox__label--disabled": disabled,
  });

  return (
    <div className={checkboxClassNames}>
      <div className={styles.checkbox__input}>
        {checked ? <IconCheckBlue /> : <IconSquareBlue />}
        <input
          checked={checked}
          defaultChecked={defaultChecked}
          disabled={disabled}
          name={name}
          onChange={onChange}
          type="checkbox"
          readOnly={readOnly}
          // key={name}
        />
      </div>
      {label && <span className={labelClassNames}>{label}</span>}
      {/* {isLoading && <Loader />} */}
    </div>
  );
}

Checkbox.propTypes = {};

Checkbox.defaultProps = {};

export default Checkbox;
