// Modules
import React from 'react';

function IconCheckBlue() {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.33333 0C2.44928 0 1.60143 0.351189 0.976311 0.976311C0.351189 1.60143 0 2.44928 0 3.33333V12.6667C0 13.5507 0.351189 14.3986 0.976311 15.0237C1.60143 15.6488 2.44928 16 3.33333 16H12.6667C13.5507 16 14.3986 15.6488 15.0237 15.0237C15.6488 14.3986 16 13.5507 16 12.6667V3.33333C16 2.44928 15.6488 1.60143 15.0237 0.976311C14.3986 0.351189 13.5507 0 12.6667 0H3.33333ZM1.33333 3.33333C1.33333 2.8029 1.54405 2.29419 1.91912 1.91912C2.29419 1.54405 2.8029 1.33333 3.33333 1.33333H12.6667C13.1971 1.33333 13.7058 1.54405 14.0809 1.91912C14.456 2.29419 14.6667 2.8029 14.6667 3.33333V12.6667C14.6667 13.1971 14.456 13.7058 14.0809 14.0809C13.7058 14.456 13.1971 14.6667 12.6667 14.6667H3.33333C2.8029 14.6667 2.29419 14.456 1.91912 14.0809C1.54405 13.7058 1.33333 13.1971 1.33333 12.6667V3.33333ZM11.8053 6.472C11.9305 6.34682 12.0008 6.17703 12.0008 6C12.0008 5.82297 11.9305 5.65318 11.8053 5.528C11.6802 5.40282 11.5104 5.33249 11.3333 5.33249C11.1563 5.33249 10.9865 5.40282 10.8613 5.528L6.66667 9.724L5.13867 8.19467C5.01348 8.06948 4.8437 7.99916 4.66667 7.99916C4.48963 7.99916 4.31985 8.06948 4.19467 8.19467C4.06948 8.31985 3.99916 8.48963 3.99916 8.66667C3.99916 8.8437 4.06948 9.01348 4.19467 9.13867L6.19467 11.1387C6.25659 11.2008 6.33016 11.25 6.41116 11.2836C6.49215 11.3172 6.57898 11.3345 6.66667 11.3345C6.75436 11.3345 6.84119 11.3172 6.92218 11.2836C7.00317 11.25 7.07674 11.2008 7.13867 11.1387L11.8053 6.472Z'
        fill='#2A3960'
      />
    </svg>
  );
}

export default IconCheckBlue;
