import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";

// Components
import ProtectedRoute from "components/ProtectedRoute";

// Screens
import Dashboard from "screens/Dashboard";
import DashboardSingleSite from "screens/DashboardSingleSite";
import DashboardActivation from "screens/DashboardActivation";
import Welcome from "screens/Welcome";
import RegulationBot from 'screens/RegulationBot';
import Protocol from "screens/Protocol";
import SignIn from "screens/SignIn";
import SignUp from "screens/SignUp";
import SocialLoginRedirect from "screens/SocialLoginRedirect";
import ProtocolQA from 'screens/ProtocolQA/ProtocolQA';
import LandingPage from 'screens/LandingPage';
import { useStore } from 'index';
import PrivacyPolicy from 'screens/PrivacyPolicy';
import TermsOfService from 'screens/Terms';
import VerifyEmail from 'screens/VerifyEmail';
import ForgotPassword from 'screens/ForgotPassword';
import ResetPassword from 'screens/ResetPassword';
import { LinearProgress, Link, Typography } from '@mui/material';
import IconChevronLeft from 'assets/images/svg/IconChevronLeft';
import { getPreviousPage } from 'helpers';
import EmailConfirmationFailed from 'screens/Error/EmailConfirmationFailed';

const AppRoutes = () => {
  const [user, isUserLoading] = useStore(state => [state.user, state.isUserLoading]);

  return (
    <Routes>
      <Route exact path="/" element={<LandingPage />} />
      <Route path="/northstar/terms-of-service" element={<TermsOfService />} />
      <Route path="/northstar/privacy-policy" element={<PrivacyPolicy />} />

      <Route path="/verify-email" element={<VerifyEmail />} />
      <Route path="/email-verified/redirect" element={<Navigate to={getPreviousPage()} replace />} />
      <Route path="/email-confirmation-failed" element={<EmailConfirmationFailed />} />

      <Route
        element={
          isUserLoading ? <LinearProgress /> : 
          <ProtectedRoute withHistory={false} isAllowed={!user} redirectPath={"/northstar"} />
        }
      >
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/connect/:providerName/redirect" element={<SocialLoginRedirect />} />

        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </Route> 

      <Route
        element={
          isUserLoading ? <LinearProgress /> : 
          <ProtectedRoute withHistory={true} isAllowed={user?.confirmed} redirectPath={(user && !user.confirmed) ? `/verify-email?email=${user.email}` : '/signin'} />
        }
      >
        <Route path="/northstar" element={<RegulationBot />} />

        <Route path="/protocol" element={<Protocol />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/dashboard-selection" element={<Dashboard />} />
        <Route path="/dashboard" element={<DashboardSingleSite />} />
        <Route path="/dashboard-activation" element={<DashboardActivation />} />
        <Route path="/protocol-qa" element={<ProtocolQA />} />
      </Route>
      
      <Route
        path="*"
        element={
          <div>
            <Typography variant="h3">
                404 Page not found
            </Typography>
            <Link href="/northstar" underline="hover">
                <IconChevronLeft />
                Back to Northstar
            </Link>
          </div>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
