import { Container, Grid, TextField, Snackbar, Alert, Paper, Typography, Button } from "@mui/material";
import { useRef } from "react";
import { BACKEND_API } from "constants";
import { message } from "antd";

function ForgotPassword() {
    const emailRef = useRef(null);

    const onFinish = async () => {
        try {
            if (!emailRef.current.value) {
                throw new Error("Please enter a valid email");
            }

            const response = await fetch(`${BACKEND_API}/auth/forgot-password`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  email: emailRef.current.value,
                }),
            });
            
            const data = await response.json();
            if (response.status != 200) {
                throw new Error(data.error.message);
            }
            
            message.success("Your reset link sent, please check your mail to reset your password!");
        } catch (err) {
            message.error(err.message);
        }
    }

    return (
    <Container maxWidth="lg">
        <Grid container spacing={2} alignItems="center" justifyContent="space-around">
            <Grid item xs={12} md={6} >
                <Paper elevation={0} sx={{ padding: 6 }}>
                    <Typography variant="h5" gutterBottom>
                        Forgot your password?
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: 2 }}>
                        Don't worry. Just tell us the email address you registered with SiteAI.
                    </Typography>
                    <TextField inputRef={emailRef} required label="Email" variant="outlined" fullWidth margin="dense" size="small" />
                    <Button variant="contained" color="primary" fullWidth onClick={onFinish} >
                        Send reset link
                    </Button>
                </Paper>
            </Grid>
        </Grid>
    </Container>);
}

export default ForgotPassword;