import { message } from "antd";
import { BACKEND_API, BEARER } from 'constants.js';
import { useEffect } from 'react';
import { getToken } from "helpers";
import { useStore } from 'index';

const useAuthProvider = () => {
  const [user, setUser, setUserLoading] = useStore(state => [state.user, state.setUser, state.setUserLoading]);
  const authToken = getToken();

  const fetchLoggedInUser = async (token) => {
    try {
      setUserLoading(true);
      const response = await fetch(`${BACKEND_API}/users/me?populate=role`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const data = await response.json();
      if (response.status === 401) {
        throw new Error(data.error.message);
      }

      setUser(data);
      // navigate(ROUTES.welcome);
    } catch (error) {
      //message.error(error.message);
    } finally {
      setUserLoading(false);
    }
  };


  useEffect(() => {
    if (authToken) {
      fetchLoggedInUser(authToken);
    } else {
      setUserLoading(false);
    }
  }, [authToken, setUserLoading]);

  return { user };
};

export default useAuthProvider;
