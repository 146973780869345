import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import SiteRankProvider from './components/SiteRankProvider';
import RecommendedActionsProvider from './components/RecommendedActionsProvider';
import { create } from 'zustand';
import { BrowserRouter as Router } from 'react-router-dom';

export const useStore = create((set) => ({
  loading: false,
  trialFilter: {},
  phases: [],
  commonalities: {},
  territory: 'US',
  user: undefined,
  isUserLoading: true,
  setUserLoading: (data) => set(() => ({ isUserLoading: data})),
  setUser: (data) => set(() => ({ user: data })),
  setLoading: (data) => set(() => ({ loading: data })),
  setTrialFilter: (data) => set(() => ({ trialFilter: data })),
  setPhases: (data) => set(() => ({ phases: data })),
  setCommonalities: (data) => set(() => ({ commonalities: data })),
  setTerritory: (data) => set(() => ({ territory: data }))
}))

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
        <SiteRankProvider>
          <RecommendedActionsProvider>
            <App />
        </RecommendedActionsProvider>
        </SiteRankProvider>
    </Router>
  </React.StrictMode>
);
