import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BACKEND_API } from "constants.js";
import { setToken, getPreviousPage } from "helpers";
import { Container, Grid, Paper, TextField, Button, Typography, Link, Box } from "@mui/material";
import { message } from 'antd';
import VideoPlayer from 'components/VideoPlayer'

// Styles
import styles from "./s.module.scss";
import { useStore } from 'index';
import IconGoogleCircle from "assets/images/svg/IconGoogle";
import IconLinkedin from "assets/images/svg/IconLinkedin";

const videoJsOptions = {
  autoplay: "muted",
  preload: "metadata",
  loop: true,
  controls: true,
  responsive: true,
  fluid: true,
  poster: "/images/clinical_background.webp",
  sources: [{
    src: '/videos/SiteAI.mp4',
    type: 'video/mp4'
  }]
};

const SignIn = () => {
  const navigate = useNavigate();
  const [setUser, isUserLoading, setUserLoading] = useStore(state => [state.setUser, state.isUserLoading, state.setUserLoading]);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const playerRef = React.useRef(null);

  const handlePlayerReady = (player) => {
    playerRef.current = player;

  };

  const onFinish = async (event) => {
    event.preventDefault();
    
    if (!emailRef.current.value || !passwordRef.current.value) {
      message.error(`Please fill all fields`);
      return;
    }

    setUserLoading(true);
    const value = {
      identifier: emailRef.current.value,
      password:  passwordRef.current.value,
    };

    try {
      
      const response = await fetch(`${BACKEND_API}/auth/local`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      });
      const data = await response.json();

      if (data.error) {
        throw new Error(data.error.message);
      }

      setToken(data.jwt);
      setUser(data.user);
      navigate(`/verify-email?email=${value.identifier}`, { replace: true });
      message.success(`Welcome back ${data.user.username}!`);
    } catch (error) {
      if (error.message.includes("Your account email is not confirmed")) {
        navigate(`/verify-email?email=${value.identifier}`, { replace: true });
      }
      message.error(error?.message ?? "Something went wrong!");
    } finally {
      setUserLoading(false);
    }
  };


  return (
    <div className={styles.centered_container}>
      <Container maxWidth={false} >
        <Grid container spacing={2} alignItems="center" justifyContent="space-around">
          {/* Left side: Login Form */}
          <Grid item xs={12} md={4} >
            <Paper elevation={0} sx={{ padding: 6 }}>
              <Typography variant="h5" gutterBottom>
                Sign In
              </Typography>
              <Link href={`${BACKEND_API}/connect/google`} >
                <Button variant="outlined" fullWidth startIcon={<IconGoogleCircle />}>
                  Sign In with Google
                </Button>
              </Link>
              <Link href={`${BACKEND_API}/connect/linkedin`} >
                <Button variant="outlined" fullWidth startIcon={<IconLinkedin />}>
                  Sign In with Linkedin
                </Button>
              </Link>
              <Typography variant="subtitle2" sx={{ marginY: 2 }}>
                Or sign in with email:
              </Typography>
              <TextField inputRef={emailRef} required label="Email" variant="outlined" fullWidth margin="dense" size="small" />
              <TextField inputRef={passwordRef} required label="Password" variant="outlined" fullWidth margin="dense" type="password" size="small" />
              <Button variant="contained" color="primary" fullWidth onClick={onFinish} >
                Sign In
              </Button>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2" sx={{ marginTop: 2 }}>
                  Don't have an account? <Link href="/signup">Sign up</Link> 
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 2 }}> 
                  <Link href="/forgot-password">Forgot my password</Link>
                </Typography>
              </Box>
             
            </Paper>
          </Grid>
          {/* Right side: Video */}
          <Grid item xs={12} md={8}>
            <VideoPlayer options={videoJsOptions} onReady={handlePlayerReady} />
          </Grid>
        </Grid>
      </Container>
      </div>
  );
};

export default SignIn;
