// Modules
import SliderBasic from "@mui/material/Slider";
import { styled } from "@mui/material/styles";

// Styles

const SliderStyled = styled(SliderBasic)({
  height: 8,
  width: "165px",
  color: "#2A3960",

  "& .MuiSlider-track": {
    backgroundColor: "#2A3960",
  },
  "& .MuiSlider-rail": {
    backgroundColor: "#F6F9FF",
  },

  "& .MuiSlider-thumb": {
    height: 28,
    width: 28,
    backgroundColor: "#2A3960",
    borderColor: "#2A3960",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "transparent",
  },

  // '& .MuiSlider-valueLabel': {
  //   lineHeight: 1.2,
  //   fontSize: 12,
  //   background: 'unset',
  //   padding: 0,
  //   width: 32,
  //   height: 32,
  //   borderRadius: '50% 50% 50% 0',
  //   backgroundColor: '#52af77',
  //   transformOrigin: 'bottom left',
  //   transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
  //   '&:before': { display: 'none' },
  //   '&.MuiSlider-valueLabelOpen': {
  //     transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
  //   },
  //   '& > *': {
  //     transform: 'rotate(45deg)',
  //   },
  // },
});

function Slider(props) {
  const {
    className,
    ariaLabel,
    defaultValue,
    step,
    marks,
    min,
    max,
    onChange,
    value,
  } = props;

  return (
    <SliderStyled
      // ariaLabel={ariaLabel}
      // className={classNames}
      className={className}
      defaultValue={defaultValue}
      valueLabelDisplay="on"
      step={step}
      marks={marks}
      min={min}
      max={max}
      value={value}
      onChange={onChange}
    />
  );
}



export default Slider;
