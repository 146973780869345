import React from "react";
import { AppBar, Toolbar, Box, Typography, Link } from '@mui/material';

export const Header = () => (
    <AppBar position="static" color="inherit" elevation={0}>
        <Toolbar >
            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <img src="/images/logo.webp" alt="Logo" height="40" />
            </Box>
            <Box sx={{
                    flexGrow: 1,
                    display: 'flex',
                    alignItems: 'center',
                }}>
                <Typography variant='body1' sx={{
                        fontSize: '20px',
                        textDecoration: 'none',
                        borderBottom: '2px solid yellow',
                    }}>
                    NorthStar is now live. Click <Link href="/northstar">here</Link> to access it
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '20px',
                }}
            >
                <Typography
                    variant='body1'
                    sx={{
                        letterSpacing: '3px',
                        fontSize: '18px',
                        textDecoration: 'none',
                        borderBottom: '2px solid yellow',
                    }}
                >
                    HOME
                </Typography>
            </Box>
        </Toolbar>
    </AppBar>
);