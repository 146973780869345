import { AUTH_TOKEN } from 'constants.js';
import reduce from "lodash/reduce";
import { ROUTES } from "constants/routes";

export const getToken = () => {
  return localStorage.getItem(AUTH_TOKEN);
};

export const setToken = (token) => {
  if (token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
};

export const removeToken = () => {
  localStorage.removeItem(AUTH_TOKEN);
};

export const getPreviousPage = () => {
  return localStorage.getItem('prevPage') || ROUTES.northstar
}

export const formatSitesData = (raw) => {
  return reduce(
    raw,
    (acc, item) => {
      acc.push({ ...item?.attributes, id: item?.id });
      return acc;
    },
    []
  );
};

export const formatStrapiResponse = (raw) => {
  return reduce(
    raw,
    (acc, item) => {
      acc.push({ ...item?.attributes, id: item?.id });
      return acc;
    },
    []
  );
};

export const calculateCostIncrease = (sitesCount) => {
  return sitesCount <= 15 ? 0 : (sitesCount - 15) * 5;
};

export const calculateProbofCompleting = (sitesCount) => {
  return sitesCount <= 15
    ? 95
    : 95 + (sitesCount - 15) < 100
    ? 95 + (sitesCount - 15)
    : 99;
};

export const randomRgba = () => {
  var o = Math.round, r = Math.random, s = 255;
  return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + r().toFixed(1) + ')';
}
