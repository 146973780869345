// Modules
import React from 'react';

function IconCheckGreen(props) {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 0.875C4.51992 0.875 0.875 4.51992 0.875 9C0.875 13.4801 4.51992 17.125 9 17.125C13.4801 17.125 17.125 13.4801 17.125 9C17.125 4.51992 13.4801 0.875 9 0.875ZM13.2285 6.27695L7.97852 12.527C7.86199 12.6657 7.69096 12.7471 7.50977 12.75H7.49922C7.32206 12.7499 7.15324 12.6747 7.03477 12.543L4.78477 10.043C4.62921 9.87799 4.57522 9.6417 4.64368 9.42553C4.71214 9.20936 4.8923 9.04722 5.11447 9.00185C5.33663 8.95649 5.56594 9.03501 5.71367 9.20703L7.48281 11.1727L12.2715 5.47305C12.495 5.21464 12.8846 5.18359 13.1462 5.40335C13.4078 5.6231 13.4445 6.0122 13.2285 6.27695Z'
        fill='#59C49A'
      />
    </svg>
  );
}

export default IconCheckGreen;
