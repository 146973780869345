import { Container, ListItem, Typography, Link } from '@mui/material';
import React from 'react';
import styles from './s.module.scss';
import IconChevronLeft from 'assets/images/svg/IconChevronLeft';

function PrivacyPolicy() {
    return (
        <Container>
            <div>
                <Link href="/northstar" underline="hover" className={styles.navigation}>
                    <IconChevronLeft />
                    Back to Northstar
                </Link>
                <Typography variant="h2" component="h1" gutterBottom={true} className={styles.heading}>
                    SiteAI Privacy Policy
                </Typography>
                <Typography variant="body2" paragraph={true} className={styles.lastUpdated}>
                Last Updated July 14th, 2023
                </Typography>
                <Typography variant="body2" paragraph={true} gutterBottom={true}>
                    This privacy policy (“Policy”) describes how www.siteai.io (“SiteAI”, “we”, “us”, or “our”) collects, uses, shares, and protects information about you when you use our website, products, and services (collectively, the “Services”).
                </Typography>

                <Typography variant="h3" component="h2" gutterBottom={true} className={styles.heading}>
                    Our Services
                </Typography>
                <Typography variant="body2" paragraph={true} gutterBottom={true}>
                SiteAI Provides a Regulatory Q&A Tool with the Brand Name of SiteAI NorthStar and available via https://siteai.io and associated internet domains.
                </Typography>

                <Typography variant="h3" component="h2" gutterBottom={true} className={styles.heading}>
                    Information SiteAI Collects
                </Typography>
                <Typography variant="body2" paragraph={true} gutterBottom={true}>
                When you visit our website, we may collect certain information automatically from your device. This information may include your IP address, device type, unique device identification numbers, browser-type, broad geographic location (e.g., country or city-level location), and other technical information. We may also collect information about how your device has interacted with our website, including the pages accessed and links clicked. Collecting this information enables us to better understand the visitors who come to our website, where they come from, and what content is of interest to them. We use this information for our internal analytics purposes and to improve the quality and relevance of our service.
                </Typography>
                <Typography variant="body2" paragraph={true} gutterBottom={true}>
                If you create an account on our website, we will collect certain information from you, including your name, email address, and password. We use this information to provide you with access to your account and to provide you with customer support.
                </Typography>

                <Typography variant="h3" component="h2" gutterBottom={true} className={styles.heading}>
                    Information SiteAI Does NOT Collect
                </Typography>
                <Typography variant="body2" paragraph={true} gutterBottom={true}>
                We do not knowingly collect any sensitive information from you, such as information about your race or ethnic origin, political opinions, religion, or health.
                </Typography>

                <Typography variant="h3" component="h2" gutterBottom={true} className={styles.heading}>
                Sharing of Information
                </Typography>
                <Typography variant="body2" paragraph={true} gutterBottom={true}>
                We do not sell, trade, or otherwise share your personal information with third parties, except as described in this Policy. We may share your personal information with the following types of third parties:
                </Typography>
                <ul className={styles.list}>
                    <Typography variant="body2" component="li" className={styles.listItem}>
                    Future Service provides who might enhance NorthStar and other module experience.
                    </Typography>
                    <Typography variant="body2" component="li" className={styles.listItem}>
                    Future Analytics Service Providers who might track website usage.
                    </Typography>
                </ul>

                <Typography variant="h3" component="h2" gutterBottom={true} className={styles.heading}>
                Third Party Services
                </Typography>
                <Typography variant="body2" paragraph={true} gutterBottom={true}>
                We may use third party services, such as Application Programming Interfaces (APIs), to maintain and improve our services. For example, information may be transmitted to OpenAI API to serve requests using their Large Language Model (LLM).
                </Typography>

                <Typography variant="h3" component="h2" gutterBottom={true} className={styles.heading}>
                Security
                </Typography>
                <Typography variant="body2" paragraph={true} gutterBottom={true}>
                We take reasonable measures to protect the personal information that we collect from you. Data is stored securely on Azure. However, no security measure is perfect, and we cannot guarantee the security of your personal information.
                </Typography>

                <Typography variant="h3" component="h2" gutterBottom={true} className={styles.heading}>
                Data Retention
                </Typography>
                <Typography variant="body2" paragraph={true} gutterBottom={true}>
                We retain your personal information for as long as your account is active or as needed to provide you with our Services. If you delete your account, we will delete your personal information from our servers within 30 days. Please contact us at support@siteai.io to request deletion.
                </Typography>

                <Typography variant="h3" component="h2" gutterBottom={true} className={styles.heading}>
                Changes to our Privacy Policy
                </Typography>
                <Typography variant="body2" paragraph={true} gutterBottom={true}>
                We may change this Policy from time to time. If we make changes, we will post the revised Policy on our website and update the “Last updated” date above. We encourage you to review the Policy whenever you access or use our Services or otherwise interact with us to stay informed about our information practices and the choices available to you.
                </Typography>

                <Typography variant="h3" component="h2" gutterBottom={true} className={styles.heading}>
                Contact Us
                </Typography>
                <Typography variant="body2" paragraph={true} gutterBottom={true}>
                If you have any questions about this Policy, please contact us at support@siteai.io 
                </Typography>

            </div>
        </Container>
    
    );
}

export default PrivacyPolicy;