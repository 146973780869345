// Modules
import {Form, Button, Input, Select} from 'antd';
import qs from "qs";
import {useState} from "react";
import {useStore} from 'index';
import debounce from 'lodash/debounce';

// Assets
import styles from "./s.module.scss";

// API
import { getSimilarCriteria } from "api";
import CriteriaTable from './CriteriaTable';

const criteriaOptions = [
  {value: 'inclusion', label: 'Inclusion'}, 
  {value: 'exclusion', label: 'Exclusion'},
  {value: 'primary_outcome', label: 'Primary Outcome'},
  {value: 'secondary_outcome', label: 'Secondary Outcome'},
];


function ProtocolCriteria() {
  const [loading, setLoading] = useStore((state) => [state.loading, state.setLoading]);
  const [similarCriteria, setSimilarCriteria] = useState([]);
  const initial_query = useStore((state) => state.commonalities.query);

  const onSubmit = debounce(async (values) => {
    setLoading(true);
    const body = {
      initial_query: initial_query || "",
      criteria: values.criteria || "",
      type: values.type || ""
    };
    console.log(body);
    const data = await getSimilarCriteria(body);

    setSimilarCriteria(data);
    // to mimic loading on fast load
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, 300);

  return (
    <div className={styles.container}>
      <h4 className={styles.header}>Criteria Search </h4>
      <Form onFinish={onSubmit} layout="vertical" initialValues={{criteria: "", type: "inclusion"}}>
      <Form.Item name="criteria" label="Criteria Text">
        <Input.TextArea autoSize />
      </Form.Item>
      <Form.Item name="type" label="Type" className={styles.type_item}>
        <Select options={criteriaOptions} />
      </Form.Item>
      <Form.Item>
          <Button htmlType="submit" className={styles.submit_button} disabled={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
      {similarCriteria.length > 0 && <CriteriaTable similarCriteria={similarCriteria}/>}
    </div>
);
}

export default ProtocolCriteria;
