import React from 'react';
import { useLocation } from 'react-router-dom';
import AppHeader from "components/AppHeader";
import AppRoutes from "components/AppRoutes";
import { StyledEngineProvider, ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material/styles";
import {Container} from '@mui/material';
import useAuthProvider from 'hooks/useAuthProvider';
import Footer from 'components/Footer';
import styles from './s.module.scss';

let theme = createTheme({
  palette: {
    primary: {
      main: '#142859',
    },
    secondary: {
      main: '#009688',
    },
  },
  typography: {
    fontFamily: [
      "Poppins","Roboto","Helvetica","Arial","sans-serif"
    ].join(","),
    h2 : {
      margin: 0,
      fontWeight: 400,
      fontSize: '2.125rem',
      lineHeight: 1.235,
      letterSpacing:'0.00735em',
      marginBottom: '0.35em'
    },
    h3: {
      margin: 0,
      fontWeight: 400,
      fontSize: '1.5rem',
      lineHeight: 1.334,
      letterSpacing:'0em',
      marginBottom: '0.35em'
    },
    body2: {
      margin: 0,
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
      marginBottom: '16px'
    }
  }
});

const App = () => {
  const {user} = useAuthProvider();
  const location = useLocation();
  const hideComponentsForRoutes = ['/'];

  return (
      <ThemeProvider theme={theme} >
        <StyledEngineProvider injectFirst>
            <div className={styles.app}>
              {!hideComponentsForRoutes.includes(location.pathname) && <AppHeader /> }
              <Container component="main" sx={{ flex: '1 0 auto' }} maxWidth={false} >
                <AppRoutes />
              </Container>
              {!hideComponentsForRoutes.includes(location.pathname) && <Footer /> }
            </div>
        </StyledEngineProvider>
      </ThemeProvider>
  );
};

export default App;
