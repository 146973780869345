// Modules
import {Table} from 'antd';

// Assets
import styles from "./s.module.scss";

const columns = [
    {
      title: 'Description',
      dataIndex: 'criteria_text',
      key: 'criteria_text',
      align: 'left'
    },
    {
      title: 'Similarity',
      dataIndex: 'similarity_metric',
      key: 'similarity_metric',
      align: 'center'
    }
  ];

export default function CriteriaTable({similarCriteria}) {
  return (
    <>
      <h4 className={styles.header}>Common Criteria In Similar Trials</h4>
      <Table rowKey="criteria_text" dataSource={similarCriteria} columns={columns} pagination={false} />
    </>
  )
}
