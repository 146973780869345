// icon:arrow-down-bold-outline | Material Design Icons https://materialdesignicons.com/ | Austin Andrews
import * as React from "react";

function IconArrowDownBoldOutline(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M22 11L12 21 2 11h6V3h8v8h6m-10 7l5-5h-3V5h-4v8H7l5 5z" />
    </svg>
  );
}

export default IconArrowDownBoldOutline;
