// Modules
import React from 'react';
import colors from 'constants/colors';

function IconNotActive() {
  return (
    <div
      style={{
        background: colors.yellow,
        borderRadius: '50%',
        width: '16px',
        height: '16px',
      }}
    />
  );
}

export default IconNotActive;
