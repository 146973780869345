import { Container, ListItem, Typography, Link } from '@mui/material';
import React from 'react';
import styles from './s.module.scss';
import IconChevronLeft from 'assets/images/svg/IconChevronLeft';

function TermsOfService() {
    return (
        <Container>
            <div>
                <Link href="/northstar" underline="hover" className={styles.navigation}>
                    <IconChevronLeft />
                    Back to Northstar
                </Link>
                <Typography variant="h2" component="h1" gutterBottom={true} className={styles.heading}>
                SiteAI Terms of Service
                </Typography>
                <Typography variant="body2" paragraph={true} className={styles.lastUpdated}>
                Last Updated June 12th, 2023
                </Typography>
                <Typography variant="body2" paragraph={true} gutterBottom={true}>
                Please read these Terms of Service (“Terms”, “Terms of Service”) carefully before using the www.siteai.io website (the “Service”) operated by SiteAI (“us”, “we”, or “our”).
                </Typography>
                <Typography variant="body2" paragraph={true} gutterBottom={true}>
                Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.
                </Typography>
                <Typography variant="body2" paragraph={true} gutterBottom={true}>
                By accessing or using the Service you agree to be bound by these Terms. SiteAI reserves the right, in its sole discretion, to restrict, suspend, or terminate your access to and use of the Service.
                </Typography>

                <Typography variant="h3" component="h2" gutterBottom={true} className={styles.heading}>
                    Our Services
                </Typography>
                <Typography variant="body2" paragraph={true} gutterBottom={true}>
                SiteAI Provides a Regulatory Q&A Tool with the Brand Name of SiteAI NorthStar and available via https://siteai.io and associated internet domains.
                </Typography>

                <Typography variant="h3" component="h2" gutterBottom={true} className={styles.heading}>
                Acceptable Use
                </Typography>
                <Typography variant="body2" paragraph={true} gutterBottom={true}>
                You agree not to use the Service in any way that causes, or may cause, damage to the Service or impairment of the availability or accessibility of the Service.
                </Typography>
                <Typography variant="body2" paragraph={true} gutterBottom={true}>
                You agree not to use the Service for unlawful, illegal, fraudulent, or harmful activities, including but not limited to hate, harassment, violence, political manipulation, spam, or malware.
                </Typography>
                <Typography variant="body2" paragraph={true} gutterBottom={true}>
                You agree not to conduct any systematic or automated data collection activities (including scraping, data mining, data extraction and data harvesting) on or in relation to the Service without our express written consent. Prohibited data collection includes, but is not limited to, using the Service as input into other services, websites, or databases. You agree not to republish any content generated by the Service without clearly citing the Service as well as the context associated with the content. Misrepresentation of the source of the content or the nature of its creation is strictly prohibited.
                </Typography>

                <Typography variant="h3" component="h2" gutterBottom={true} className={styles.heading}>
                Intellectual Property Rights
                </Typography>
                <Typography variant="body2" paragraph={true} gutterBottom={true}>
                SiteAI acknowledges and respects the intellectual property rights of all individuals and entities and expects all users of the Service to do the same. As a user of the Service, you are granted access for your own use only. Any attempts to reverse engineer, copy, reproduce, or otherwise steal intellectual property from the Service will not be tolerated and may result in legal action.
                </Typography>
                <Typography variant="body2" paragraph={true} gutterBottom={true}>
                SiteAI takes all allegations of copyright infringement seriously and will respond promptly to any notices that comply with applicable laws and are properly provided to us. If you believe that your work has been used in a manner that constitutes copyright infringement, please contact us at support@siteai.io
                </Typography>

                <Typography variant="h3" component="h2" gutterBottom={true} className={styles.heading}>
                Limitation of Liability
                </Typography>
                <Typography variant="body2" paragraph={true} gutterBottom={true}>
                In no event shall SiteAI, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.
                </Typography>
                

                <Typography variant="h3" component="h2" gutterBottom={true} className={styles.heading}>
                Disclaimer

                </Typography>
                <Typography variant="body2" paragraph={true} gutterBottom={true}>
                Your use of the Service is at your sole risk. The Service is provided on an “AS IS” and “AS AVAILABLE” basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement, or course of performance.
                </Typography>
                <Typography variant="body2" paragraph={true} gutterBottom={true}>
                SiteAI, its affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure, or available at any time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; nor d) the results of using the Service will meet your requirements.
                </Typography>
                <Typography variant="body2" paragraph={true} gutterBottom={true}>
                For the SiteAI NorthStar service, we utilize official links from FDA and EU Clinical Trial Regulation Documents.  Please validate our responses by going to the links we reference in our responses and reach out to the respective regulatory bodies as needed.
                </Typography>

                <Typography variant="h3" component="h2" gutterBottom={true} className={styles.heading}>
                Indemnification
                </Typography>
                <Typography variant="body2" paragraph={true} gutterBottom={true}>
                You agree to defend, indemnify and hold harmless SiteAI and its licensee and licensors, and their employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney’s fees), resulting from or arising out of a) your use and access of the Service, by you or any person using your account and password; b) a breach of these Terms, or c) Content posted on the Service.
                </Typography>

                <Typography variant="h3" component="h2" gutterBottom={true} className={styles.heading}>
                Changes
                </Typography>
                <Typography variant="body2" paragraph={true} gutterBottom={true}>
                We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is of material significance, we will try to provide at least 30 days’ notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
                </Typography>
                <Typography variant="body2" paragraph={true} gutterBottom={true}>
                After modification, we will post the revised Terms and update the “Last updated” date above. By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please cease using the Service.
                </Typography>


            </div>
        </Container>
    
    );
}

export default TermsOfService;