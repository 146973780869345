export const SITES_LABELS = {
  activated: 'activated',
  name: 'Site/PI  Name',
  PIExperience: 'Year Of PI Experience',
  estimatedRandomizations: 'Estimated Randomizations',
  pastStudies: 'Past Studies in this Indication',
  qualifiedPatients: 'Number of Qualified Patients',
  siteRank: 'Site rank',
  siteScore: 'Site/FQ Score',
  id: 'ID',
};
