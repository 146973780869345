import React from 'react';
import { Typography, Box, Divider, Grid } from '@mui/material';

const services = [
    {
        title: 'Intelligent Protocol Design',
        description: 'We compare your Study Protocol with similar protocols and identify any I/E criteria that might impact your enrollment timelines.',
    },
    {
        title: 'Site Selection',
        description: 'We take Site Data, Site Capabilities, Patient Data, Past Trial Performance and evaluate each Site with over 100+ variables.',
    },
    {
        title: 'Enrollment Monitoring',
        description: 'We immediately identify high performing sites as well as poor performing ones and offer corrective actions.',
    },
    {
        title: 'Continuous Feedback',
        description: 'Sponsors, CROs and Digital Clinical Trial Platforms receive continuous feedback on Site and Trial Performances, Protocol Amendments, and recommended corrective actions.',
    },
];

export const Services = () => {
    return <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            boxSizing: 'border-box',
            position: 'relative',
            gap: '20px',
            maxWidth: '90%'
        }}
    >
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '20px'
            }}>
            <Typography variant="h5" component="h2" sx={{
                backgroundColor: 'black',
                color: 'white',
                padding: '5px',
                width: 'fit-content' }}>
                GRADUATE OF NORRSKEN IMPACT ACCELERATOR 2022
            </Typography>
            <Typography>
                SiteAI has successfully completed ithe prestigious Norrsken Impact Accelerator Program along with its Pre-Seed Investment.
            </Typography>
        </Box>

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '20px'
            }}
        >
            <Typography variant="h5" component="h2" sx={{ 
                backgroundColor: 'black',
                color: 'white',
                padding: '5px',
                width: 'fit-content' }}>
                OUR SERVICES
            </Typography>
            
            <Grid container spacing={2} textAlign={"center"} maxWidth={"lg"}>
            {services.map((service, index) => (
                <Grid key={index} item xs={12} md={6}>
                     <Typography variant="h6" component="h3" gutterBottom>
                        {service.title}
                    </Typography>
                    <Divider orientation="horizontal" flexItem />
                    <Typography variant="body1" component="p">
                        {service.description}
                    </Typography>
                </Grid>
            ))}
            </Grid>
        </Box>

    </Box>
}