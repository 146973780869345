// Modules
import { useMemo } from "react";
import filter from "lodash/filter";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
// Context
import { useSiteSelectionContext } from "context/SiteSelectionContext";

// Constants
import { site_benches_names } from "constants/index";

// Styles
import styles from "./s.module.scss";

const columnHelper = createColumnHelper();

function SiteOnBenchTable(props) {
  const { data } = props;

  // const sitesOnBenches = useMemo(
  //   () =>
  //     filter(data, (site) => {
  //       return site_benches_names.includes(site.name);
  //     }),
  //   [data]
  // );

  const columns = [
    columnHelper.accessor("name", {
      header: "Site Name",
    }),
    columnHelper.accessor("rand_est_fq", {
      header: "Rand. Est. in FQ",
    }),
    columnHelper.accessor("location", {
      header: "Location",
    }),
  ];

  // const dataTable = useMemo(() => sitesOnBenches.slice(0, 3), [sitesOnBenches]);
  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <table className={styles["dashboard-table"]}>
      <thead className={styles["dashboard-table__header"]}>
        {table.getHeaderGroups().map((headerGroup, idx) => (
          <tr key={idx}>
            {headerGroup.headers.map((header, idx) => (
              <th
                key={idx}
                style={{ width: header.getSize() }}
                className={styles["dashboard-table__header__cell"]}
              >
                {header.isPlaceholder ? null : (
                  <div
                    {...{
                      className: header.column.getCanSort()
                        ? "cursor-pointer select-none"
                        : "",
                      onClick: header.column.getToggleSortingHandler(),
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className={styles["dashboard-table__body"]}>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default SiteOnBenchTable;
