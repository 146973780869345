// Modules
import {useStore} from 'index';


// Assets
import styles from "./s.module.scss";
import { Table } from 'antd';



const columns = [
  {
    title: 'Site Name',
    dataIndex: 'facility_name',
    key: 'name',
    align: 'left'
  },
  {
    title: '# of Trials',
    dataIndex: 'trial_count',
    key: 'trial_count',
    align: 'center'
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
    align: 'center'
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
    align: 'center'
  },
]

function ProtocolSites() {
  const commonalities = useStore((state) => state.commonalities);

  return (
    <div className={styles.container}>
      <h3>Study Sites</h3>
      <h4>Sites With Trials Same Condition</h4>
      <Table rowKey="name" dataSource={commonalities.suggested_sites.sites_table} columns={columns} pagination={false} />
    </div>
);
}

export default ProtocolSites;
