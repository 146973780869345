import { Container, Grid, Paper, Typography, Link, Button } from "@mui/material";

function EmailConfirmationFailed() {
    return (<Container maxWidth="lg">
    <Grid container spacing={2} alignItems="center" justifyContent="space-around">
        <Grid item xs={12} md={6} >
            <Paper elevation={0} sx={{ padding: 6 }}>
                <Typography variant="h5" gutterBottom>
                    Email Verification Failed
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 2 }}>
                    It appears that the confirmation link you used is invalid or the email address has already been verified. To access your account, kindly click the link below.
                </Typography>
                <Button href="/signin" variant="contained" color="primary" fullWidth>
                    Go back to sign in page
                </Button>
            </Paper>
        </Grid>
    </Grid>
</Container>);
}

export default EmailConfirmationFailed;