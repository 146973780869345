import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BACKEND_API } from "constants.js";
import { setToken, getPreviousPage } from "helpers";
import { Container, Grid, Paper, TextField, Button, Typography, Link } from "@mui/material";
import { message } from 'antd';
import VideoPlayer from 'components/VideoPlayer'

// Styles
import styles from "./s.module.scss";
import { useStore } from 'index';
import IconGoogleCircle from "assets/images/svg/IconGoogle";
import IconLinkedin from "assets/images/svg/IconLinkedin";

const videoJsOptions = {
  autoplay: "muted",
  preload: "none",
  loop: true,
  controls: true,
  responsive: true,
  fluid: true,
  poster: "/images/clinical_background.webp",
  sources: [{
    src: '/videos/SiteAI.mp4',
    type: 'video/mp4'
  }]
};

const SignIn = () => {
  const navigate = useNavigate();
  const [setUser, isUserLoading, setUserLoading] = useStore(state => [state.setUser, state.isUserLoading, state.setUserLoading]);
  const usernameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const playerRef = React.useRef(null);

  const handlePlayerReady = (player) => {
    playerRef.current = player;

  };

  const onFinish = async (event) => {
    event.preventDefault();

    if (!usernameRef.current.value || !emailRef.current.value || !passwordRef.current.value || !confirmPasswordRef.current.value) {
      message.error(`Please fill all the fields`);
      return;
    }

    if (passwordRef.current.value != confirmPasswordRef.current.value) {
      message.error(`Passwords do not match. Please make sure your passwords match before proceeding`);
      return;
    }

    setUserLoading(true);
    try {
      const response = await fetch(`${BACKEND_API}/auth/local/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: usernameRef.current.value,
          email: emailRef.current.value,
          password: passwordRef.current.value,
        }),
      });

      const data = await response.json();
      if (data?.error) {
        throw data?.error;
      } else {
        // set the token
        setToken(data.jwt);

        // set the user
        setUser(data.user);
        setUserLoading(false);

        navigate(`/verify-email?email=${data.user.email}`, { replace: true });
      }
    } catch (error) {
      message.error(error?.message ?? "Something went wrong!");

    } finally {
      setUserLoading(false);
    }
  };


  return (
    <div className={styles.centered_container}>
      <Container maxWidth={false} >
        <Grid container spacing={2} alignItems="center" justifyContent="space-around">
          {/* Left side: Login Form */}
          <Grid item xs={12} md={4} >
            <Paper elevation={0} sx={{ padding: 6 }}>
              <Typography variant="h5" gutterBottom>
                Sign Up
              </Typography>
              <Link href={`${BACKEND_API}/connect/google`} >
                <Button variant="outlined" fullWidth startIcon={<IconGoogleCircle />}>
                  Sign Up with Google
                </Button>
              </Link>
              <Link href={`${BACKEND_API}/connect/linkedin`} >
                <Button variant="outlined" fullWidth startIcon={<IconLinkedin />}>
                  Sign Up with Linkedin
                </Button>
              </Link>
              <Typography variant="subtitle2" sx={{ marginY: 2 }}>
                Or with email:
              </Typography>
              <TextField inputRef={usernameRef} required label="Username" variant="outlined" fullWidth margin="dense" size="small" />
              <TextField inputRef={emailRef} required label="Email" variant="outlined" fullWidth margin="dense" size="small" />
              <TextField inputRef={passwordRef} required label="Password" variant="outlined" fullWidth margin="dense" type="password" size="small" />
              <TextField inputRef={confirmPasswordRef} required label="Confirm Password" variant="outlined" fullWidth margin="dense" type="password" size="small" />
              <Typography variant="body2">
                By signing up, you accept our <Link
                target="_blank"
                href="/northstar/terms-of-service">Terms of Use</Link> and <Link
                target="_blank"
                href="/northstar/privacy-policy">Privacy Policy</Link> and agree to receive future emails (opt-out anytime).
              </Typography> 
              <Button variant="contained" color="primary" fullWidth onClick={onFinish} >
                Sign Up
              </Button>
              <Typography variant="body2" sx={{ marginTop: 2 }}>
                Already have an account? <Link href="/signin">Sign In</Link>
              </Typography>
            </Paper>
          </Grid>
          {/* Right side: Video */}
          <Grid item xs={12} md={8}>
            <VideoPlayer options={videoJsOptions} onReady={handlePlayerReady} />
          </Grid>
        </Grid>
      </Container>
      </div>
  );
};

export default SignIn;
