// Modules
import axios from 'axios';
import { useStore } from 'index';
// Helpers
import { BACKEND_API, CLINICAL_TRIALS_API, REGULATION_BOT_API, BEARER } from 'constants.js';

// Helpers
import { getToken, formatStrapiResponse } from 'helpers';

const requestInterceptor = function(config){
  const { user } = useStore.getState();
  const token = getToken();

  if (token) {
    config.headers.Authorization = `${BEARER} ${token}`;
  }

  if (user) {
    config.headers["X-User-Id"] = user.id;
    config.headers["X-User-Email"] = user.email;
  }

  return config;
}

const instance = axios.create({
  baseURL: BACKEND_API
});
instance.interceptors.request.use(requestInterceptor);

const clinicalInstance = axios.create({
  baseURL: CLINICAL_TRIALS_API
});
clinicalInstance.interceptors.request.use(requestInterceptor);

const regulationBotInstance = axios.create({
  baseURL: REGULATION_BOT_API
});
regulationBotInstance.interceptors.request.use(requestInterceptor);

// Clinical Trials API

async function parseQuery(query) {
  return clinicalInstance.get(`/parse_query_string?${query}`).then((res) => res.data || {});
}

async function getPhases() {
  return clinicalInstance.get(`/phases`).then((res) => res.data || {});
}

async function searchCondition(query) {
  return clinicalInstance.get(`/conditions_autocomplete?${query}`).then((res) => res.data || {});
}

async function searchCity(query) {
  return clinicalInstance.get(`/cities_autocomplete?${query}`).then((res) => res.data || {});
}

async function searchCountry(query) {
  return clinicalInstance.get(`/countries_autocomplete?${query}`).then((res) => res.data || {});
}

async function getCommonalities(body) {
  return clinicalInstance.post(`/commonalities`, body).then((res) => res.data || {});
}

async function getSimilarCriteria(body) {
  return clinicalInstance.post(`/criteria_similarity`, body).then((res) => res.data || {});
}

// Regulation Bot API
async function queryRegulation(query, territory) {
  return regulationBotInstance.get(`/api/regulation_qa?query=${query}&territory=${territory}`).then((res) => res.data || {});
}

async function queryProtocol(filename, query) {
  return regulationBotInstance.post(`/api/protocol_qa?filename=${filename}&query=${query}`).then((res) => res.data || {});
}

async function uploadProtocol(formData) {
  return regulationBotInstance.post('/api/protocol_qa_upload/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then((res) => res.data || {});
}

// STRAPI API

async function loadSites(query) {
  return instance.get(`/sites?${query}`).then((res) => res.data || []);
}

async function loadRecommendedActions(query) {
  return instance.get(`/recommended-actions`).then((res) => {
    return formatStrapiResponse(res.data.data) || [];
  });
}

async function loadDashboard(query) {
  return instance.get(`/dashboard`).then((res) => {
    return res.data.data || {};
  });
}

async function updateSiteById(id, data) {
  return instance.put(`/sites/${id}`, { data }).then((res) => {
    return res.data;
  });
}

async function updateRecommendedActions(id, data) {
  return instance.put(`/recommended-actions/${id}`, { data }).then((res) => {
    return res.data;
  });
}

async function loadWeeksData(query) {
  return instance.get(`/weeks-data`).then((res) => {
    return res.data.data || [];
  });
}

async function giveFeedback(user, query, answer, reaction) {
  return instance.post(`/feedbacks`, { 
    data: {
      user: user.id,
      Question: query,
      Answer: answer,
      Reaction: reaction, 
      Feedback: ""
    }
  }).then((res) => {
      return res.data.data || [];
    });
}

export {
  // Clinical API
  parseQuery,
  getPhases,
  searchCondition,
  searchCity,
  searchCountry,
  getCommonalities,
  getSimilarCriteria,
  // Regulatiob Bot
  queryRegulation,
  queryProtocol,
  uploadProtocol,
  // STRAPI API
  loadDashboard,
  loadSites,
  loadRecommendedActions,
  loadWeeksData,
  updateSiteById,
  updateRecommendedActions,
  giveFeedback
};
