// Modules
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

// Context
import { useSiteSelectionContext } from "context/SiteSelectionContext";

function DashboardChart(props) {

  const {  weeksData } =
  useSiteSelectionContext();
  const options = {
    title: {
      text: "",
    },

    yAxis: {
      gridLineDashStyle: "longdash",

      title: {
        text: "",
      },
    },

    xAxis: {
      accessibility: {
        rangeDescription: "Range: 1 wWeek  to 5 Week",
      },
      categories: ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"],
    },

    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        pointStart: 0,
      },
    },

    series: [
      {
        name: "Randomizations",
        data: weeksData?.map((week) => week.randomizations),
      },
      {
        name: "Screenings",
        data: weeksData?.map((week) => week.screenings),
      },
    ],

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}

export default DashboardChart;
