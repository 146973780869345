const { freeze } = Object;

const PATHS = freeze({
  dashboard: 'dashboard',
  signIn: 'sign-in',
  signUp: 'sign-up',
  welcome: 'welcome',
});

const { dashboard, signIn, signUp, welcome } = PATHS;

export const ROUTES = freeze({
  dashboard: `/${dashboard}`,
  signIn: `/${signIn}`,
  signUp: `/${signUp}`,
  welcome: `/${welcome}`,
});
