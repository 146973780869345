// Modules
import React from 'react';

function IconCalendarChecked() {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.0251 23C13.7418 23 13.5041 22.904 13.3121 22.712C13.1208 22.5207 13.0251 22.2833 13.0251 22V20.85C12.2751 20.6833 11.6168 20.3917 11.0501 19.975C10.4834 19.5583 10.0251 18.975 9.6751 18.225C9.55844 17.9917 9.5541 17.746 9.6621 17.488C9.77077 17.2293 9.96677 17.0417 10.2501 16.925C10.4834 16.825 10.7251 16.8293 10.9751 16.938C11.2251 17.046 11.4168 17.225 11.5501 17.475C11.8334 17.975 12.1918 18.3543 12.6251 18.613C13.0584 18.871 13.5918 19 14.2251 19C14.9084 19 15.4878 18.846 15.9631 18.538C16.4378 18.2293 16.6751 17.75 16.6751 17.1C16.6751 16.5167 16.4918 16.054 16.1251 15.712C15.7584 15.3707 14.9084 14.9833 13.5751 14.55C12.1418 14.1 11.1584 13.5627 10.6251 12.938C10.0918 12.3127 9.8251 11.55 9.8251 10.65C9.8251 9.56667 10.1751 8.725 10.8751 8.125C11.5751 7.525 12.2918 7.18333 13.0251 7.1V6C13.0251 5.71667 13.1208 5.479 13.3121 5.287C13.5041 5.09567 13.7418 5 14.0251 5C14.3084 5 14.5461 5.09567 14.7381 5.287C14.9294 5.479 15.0251 5.71667 15.0251 6V7.1C15.6584 7.2 16.2084 7.40433 16.6751 7.713C17.1418 8.021 17.5251 8.4 17.8251 8.85C17.9751 9.06667 18.0044 9.30833 17.9131 9.575C17.8211 9.84167 17.6334 10.0333 17.3501 10.15C17.1168 10.25 16.8751 10.254 16.6251 10.162C16.3751 10.0707 16.1418 9.90833 15.9251 9.675C15.7084 9.44167 15.4544 9.26233 15.1631 9.137C14.8711 9.01233 14.5084 8.95 14.0751 8.95C13.3418 8.95 12.7834 9.11267 12.4001 9.438C12.0168 9.76267 11.8251 10.1667 11.8251 10.65C11.8251 11.2 12.0751 11.6333 12.5751 11.95C13.0751 12.2667 13.9418 12.6 15.1751 12.95C16.3251 13.2833 17.1961 13.8123 17.7881 14.537C18.3794 15.2623 18.6751 16.1 18.6751 17.05C18.6751 18.2333 18.3251 19.1333 17.6251 19.75C16.9251 20.3667 16.0584 20.75 15.0251 20.9V22C15.0251 22.2833 14.9294 22.5207 14.7381 22.712C14.5461 22.904 14.3084 23 14.0251 23Z'
        fill='white'
      />
      <rect x='0.5' y='0.5' width='27' height='27' rx='3.5' stroke='white' />
    </svg>
  );
}

export default IconCalendarChecked;
