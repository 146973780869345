import React from "react";
import styles from './s.module.scss'
import { Typography, Link, Container, Divider, Box } from "@mui/material";

function Footer() {
    return (<footer className={styles.footer}>
        
        <Divider />
        <Box className={styles.footerContent}>
            <Box className={styles.copyright}>
                <Typography variant="body2" component="span" >
                    Copyright © 2023, SiteAI Inc.
                </Typography>
            </Box>
            <Box className={styles.footerLinks}>
                <Link href="mailto:support@siteai.io" underline="hover" variant="body2" className={styles.footerLink}>Contact Us</Link>
                <Link href="/northstar/terms-of-service" target="_blank" underline="hover" variant="body2" className={styles.footerLink}>Terms and Conditions</Link>
                <Link href="/northstar/privacy-policy" target="_blank" underline="hover" variant="body2" className={styles.footerLink}>Privacy Policy</Link>
            </Box>
        </Box>
        
    </footer>);
}


export default Footer;