import { useEffect } from 'react';
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { BACKEND_API } from "constants.js";
import { message } from 'antd';
import { setToken, getPreviousPage } from "helpers";
import { useStore } from 'index';

const SocialLoginRedirect = (props) => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [user, setUser, setUserLoading] = useStore(state => [state.user, state.setUser, state.setUserLoading]);


  useEffect(() => {
    async function authorize() {
      try {
        setUserLoading(true);
        const res = await fetch(`${BACKEND_API}/auth/${params.providerName}/callback${location.search}`);
        if (res.status !== 200) {
          throw new Error(`Couldn't login. Please try again.`);
        }
        const data = await res.json();
        setToken(data.jwt);
        setUser(data.user);
      } catch (err) {
        message.error(err.message);
        navigate(getPreviousPage(), { replace: true });
      } finally {
        setUserLoading(false);
      }
    }
    
    if (!user) {
      authorize();
    } else {
      navigate(getPreviousPage(), { replace: true });
    }

  }, [params.providerName, location.search, user]);

};

export default SocialLoginRedirect;