import { Box, Link, Typography, Snackbar, Alert } from "@mui/material";
import { message } from "antd";
import { BACKEND_API } from "constants";
import { getPreviousPage } from "helpers";
import { useStore } from "index";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function VerifyEmail(props) {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const [user] = useStore(state => [state.user]);

    const resendEmail = async () => {
        try {
            const response = await fetch(`${BACKEND_API}/auth/send-email-confirmation`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  email: params.get('email'),
                }),
            });
            
            if (response.status != 200) {
                throw new Error("Verification mail could not send, please try again later.");
            }
            
            message.info("Verification mail has been sent successfully, please check your email.");
        } catch (err) {
            message.error(err.message);
        }
    }

    useEffect(() => {
        if (!params.get('email')) {
            navigate(getPreviousPage(), { replace: true });
            return;
        }  

        if (user && user.confirmed) {
            navigate(getPreviousPage(), { replace: true });
        }
        
    }, [user, params]);

    return (<Box mt={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', minHeight: '50vh' }}>
        <Box>
            <Typography variant="h6">
                You're almost done! 👍
            </Typography>
            <Typography variant="body1">
                We have sent you a verification email to {params.get('email')}
            </Typography>
            <Typography variant="body1">
                You may need to check your spam folder or unblock noreply@siteai.io
            </Typography>
            <Typography variant="body2">
                Haven't received our email? <Link component="button" variant="body2" onClick={resendEmail}>Resend email</Link>
            </Typography>
        </Box>
    </Box>);
}

export default VerifyEmail;