// Modules
import { useEffect, useState, useCallback, useMemo } from "react";
import Typography from "@mui/material/Typography";
import qs from "qs";
import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import reduce from "lodash/reduce";
import set from "lodash/set";
import find from "lodash/find";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";

// API
import {
  loadSites,
  loadDashboard,
  loadRecommendedActions,
  updateRecommendedActions,
  loadWeeksData,
} from "api";

// Assets
import IconCalendar from "assets/images/svg/IconCalendar";
import IconCalendarChecked from "assets/images/svg/IconCalendarChecked";
import IconDollar from "assets/images/svg/IconDollar";

// Components
import BasicCard from "components/DashboardCard";
import SitePerfomanceTable from "components/SitePerfomanceTable";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import Loader from "components/Loader";
import Slider from "components/Slider";
import DashboardChart from "components/DashboardChart";
import SiteOnBenchTable from "components/SiteOnBenchTable";

// Context
import { useRecommendedActionsContext } from "context/RecommendedActionsContext";
import { useSiteSelectionContext } from "context/SiteSelectionContext";

// Constants
import { site_benches_names, site_perfomance_ids } from "constants/index";

// Helpers
import { formatSitesData } from "helpers";

// Styles
import styles from "./s.module.scss";

function DashboardSingleSite() {
  const [isLoading, setIsLoading] = useState(false);

  const { recommendedActions, setRecommendedActions } =
    useRecommendedActionsContext();

  const { setSites, sites, weeksData, setWeeksData } =
    useSiteSelectionContext();
  const { dashboard, setDashboard } = useSiteSelectionContext();
  const projected_completion_date = get(dashboard, "projected_completion_date");
  const projected_completion_date_formatted = projected_completion_date
    ? moment(projected_completion_date).format("MMM Do YYYY")
    : "";
  const oroginal_completeion_date = get(dashboard, "oroginal_completeion_date");
  const oroginal_completeion_date_formatted = oroginal_completeion_date
    ? moment(oroginal_completeion_date).format("MMM Do YYYY")
    : "";

  const estimate_budget_overrun = `${get(
    dashboard,
    "estimate_budget_overrun",
    "0"
  )}%`;

  const randomizations = get(dashboard, "randomizations", 0);
  const randomizations_min = get(dashboard, "randomizations_min", 0);
  const randomizations_max = get(dashboard, "randomizations_max", 100);

  const screen_failure_reasons = get(dashboard, "screen_failure_reasons");
  const screen_failure_rate = get(dashboard, "screen_failure_rate");

  const query = qs.stringify(
    {
      pagination: {
        start: 0,
        limit: 20,
      },
      sort: ["activated:desc"],
    },
    {
      encodeValuesOnly: true,
    }
  );

  const fetchSites = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await loadSites(query);

      if (data?.error) {
        throw data?.error;
      } else {
        const sites = formatSitesData(data?.data);
        setSites(sites);

        // set the token
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [query, setSites]);

  useEffect(() => {
    fetchSites();
  }, [fetchSites]);

  const fetchRecommendedActions = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await loadRecommendedActions(null);

      if (data?.error) {
        throw data?.error;
      } else {
        setRecommendedActions(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [setRecommendedActions]);

  const fetchDashboard = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await loadDashboard(null);
      setDashboard(data?.attributes);

      if (data?.error) {
        throw data?.error;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [setDashboard]);

  const fetchWeeksData = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await loadWeeksData(null);

      setWeeksData(formatSitesData(data));

      if (data?.error) {
        throw data?.error;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [setWeeksData]);

  const recommendedActionsEmpty = isEmpty(recommendedActions);

  useEffect(() => {
    if (!recommendedActionsEmpty) return;
    fetchRecommendedActions();
  }, [recommendedActionsEmpty, fetchRecommendedActions]);

  useEffect(() => {
    if (!isEmpty(dashboard)) return;
    fetchDashboard();
  }, [dashboard, fetchDashboard]);

  useEffect(() => {
    if (!isEmpty(weeksData)) return;
    fetchWeeksData();
  }, [weeksData, fetchWeeksData]);

  useEffect(() => {
    if (!isEmpty(dashboard)) return;
    fetchDashboard();
  }, [dashboard, fetchDashboard]);

  const initialCheked = reduce(
    recommendedActions,
    (acc, action) => {
      set(acc, action.name, action.active);
      return acc;
    },
    {}
  );

  const sitePerfomanceData = useMemo(
    () =>
      orderBy(
        filter(sites, (site) => {
          return site_perfomance_ids.includes(site.site_id);
        }),
        "site_id"
      ) || [],
    [sites]
  );
  const siteBenchesData = useMemo(
    () =>
      orderBy(
        filter(sites, (site) => {
          return site_benches_names.includes(site.name);
        }),
        "rand_est_fq"
      ) || [],
    [sites]
  );

  const onSubmit = (values) => {
    Object.entries(values)?.forEach(([checkedName, checkedValue]) => {
      const id = find(
        recommendedActions,
        (action) => action.name === checkedName
      )?.id;
      updateRecommendedActions(id, { active: checkedValue });
    });
  };

  return (
    <div className={styles.dashboard}>
      <div className={styles.container}>
        {/* <h2 className={styles.dashboard__title}>Site Selection Dashboard</h2> */}
        <Grid
          display="flex"
          flexDirection="column"
          rowGap="45px"
          className={styles.dashboard__top}
        >
          <Grid container alignItems="stretch" spacing={2}>
            <Grid item md={3} sm={6} xs={12}>
              <BasicCard
                icon={<IconCalendar />}
                variant="secondary"
                title="Projected Completion date"
                subtitle={projected_completion_date_formatted}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <BasicCard
                title="Original Completion date"
                subtitle={oroginal_completeion_date_formatted}
                icon={<IconCalendarChecked />}
              />
            </Grid>

            <Grid item md={3} sm={6} xs={12}>
              <BasicCard
                icon={<IconDollar />}
                variant="secondary"
                title="Estimate Budget Overrun"
                subtitle={estimate_budget_overrun}
              />
            </Grid>

            <Grid item md={3} sm={6} xs={12}>
              <BasicCard
                contentClassname={styles["card--slider__content"]}
                alignItems="center"
              >
                <Typography
                  sx={{ fontSize: 16, marginBottom: "15px" }}
                  gutterBottom
                >
                  Randomizations
                </Typography>
                <Slider
                  className={styles.slider}
                  value={randomizations}
                  min={randomizations_min}
                  max={randomizations_max}
                />
              </BasicCard>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Box
                sx={{
                  height: "100%",
                  backgroundColor: "#fff",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
                  borderRadius: "20px",
                  padding: "20px",
                }}
              >
                <DashboardChart />
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Box
                sx={{
                  height: "100%",
                  backgroundColor: "#fff",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
                  borderRadius: "20px",
                  padding: "20px 35px",
                }}
              >
                <Typography
                  className={styles["recommended-actions__title"]}
                  sx={{ fontSize: 16 }}
                  gutterBottom
                >
                  Site Perfomance
                </Typography>
                <SitePerfomanceTable data={sitePerfomanceData} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <section
          className={styles.dashboard__bottom}
          style={{ background: "#F1F4F6" }}
        >
          <Grid alignItems="stretch" container justifyContent="space-between">
            <Grid
              display="flex"
              item
              xs={4}
              sx={{ pl: 3 }}
              rowGap="10px"
              flexDirection="column"
            >
              <Typography
                className={styles["recommended-actions__title"]}
                sx={{ fontSize: 16 }}
                gutterBottom
              >
                Recommended Actions
              </Typography>
              {!isLoading && (
                <Form
                  onSubmit={onSubmit}
                  initialValues={initialCheked}
                  render={({ handleSubmit }) => {
                    return (
                      <form
                        onSubmit={handleSubmit}
                        className={styles["recommended-actions__form"]}
                      >
                        {recommendedActions.map((action, index) => {
                          const key = `${action.id}`;
                          return (
                            <Field
                              key={action.name}
                              name={action.name}
                              type="checkbox"
                            >
                              {({ input }) => {
                                return (
                                  <Checkbox
                                    label={action?.label}
                                    labelClassName={
                                      styles["checkbox--actions__label"]
                                    }
                                    {...input}
                                    name={key}
                                  />
                                );
                              }}
                            </Field>
                          );
                        })}
                        <Button
                          className={styles["recommended-actions__btn"]}
                          type="submit"
                        >
                          take action
                        </Button>
                      </form>
                    );
                  }}
                />
              )}
              {isLoading && <Loader />}
            </Grid>
            <Grid item xs={7}>
              <Box
                sx={{
                  backgroundColor: "#fff",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
                  borderRadius: "20px",
                  padding: "20px",
                }}
              >
                <Grid container>
                  <Grid item xs={4} alignItems="center" justifyContent="center">
                    <Typography
                      className={styles["card__title"]}
                      sx={{ fontSize: 16 }}
                      gutterBottom
                    >
                      Screen Failure Rate:
                    </Typography>
                    <div className={styles["chart--screen-failure"]}>
                      <Typography
                        className={styles["card__info--circle"]}
                        sx={{ fontSize: 16 }}
                      >
                        {screen_failure_rate}%
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4} alignItems="center" justifyContent="center">
                    <Typography
                      className={styles["card__title"]}
                      sx={{ fontSize: 16 }}
                      gutterBottom
                    >
                      Top Screen failure reasons:
                    </Typography>
                    <Typography
                      className={styles["card__info"]}
                      sx={{ fontSize: 16 }}
                      gutterBottom
                    >
                      {screen_failure_reasons}
                    </Typography>
                  </Grid>

                  <Grid item xs={4} alignItems="center" justifyContent="center">
                    <Typography
                      className={styles["card__title"]}
                      sx={{ fontSize: 16 }}
                      gutterBottom
                    >
                      Most Challening I/E Criteria :
                    </Typography>
                    <Typography
                      className={styles["card__info"]}
                      sx={{ fontSize: 16 }}
                      gutterBottom
                    >
                      FEV1 range of 30% - 49%
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Typography
                className={styles["recommended-actions__title"]}
                sx={{ fontSize: 16 }}
                gutterBottom
              >
                Sites on the bench
              </Typography>
              <div
                style={{
                  display: "flex",
                  columnGap: "15px",
                  alignItems: "flex-end",
                }}
              >
                <SiteOnBenchTable data={siteBenchesData} />

                <Link to="/dashboard-selection" className={styles["link__btn"]}>
                  Back to Site Selection
                </Link>
              </div>
            </Grid>
          </Grid>
        </section>
      </div>
    </div>
  );
}

export default DashboardSingleSite;
