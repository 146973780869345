import styles from './s.module.scss';
import React, { useState, useEffect } from 'react';
import { queryProtocol, uploadProtocol } from 'api';
import {Spin} from 'antd';
import {useStore} from 'index';

const ProtocolQA = () => {
  const [loading, setLoading] = useStore((state) => [state.loading, state.setLoading]);
  const [file, setFile] = useState(null);
  const [query, setQuery] = useState('');
  const [submittedQuery, setSubmittedQuery] = useState(null);
  const [queryClassName, setQueryClassName] = useState(styles.query);
  const [answer, setAnswer] = useState('');
  const [filename, setFilename] = useState('');
  const [uploadingFile, setUploadingFile] = useState(false);
  const [submittingQuery, setSubmittingQuery] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  const handleFileUpload = async (formData) => {
    try {
      setUploadingFile(true);
      const fileUploadResponse = await uploadProtocol(formData)
      setFilename(fileUploadResponse.filename);
      console.log('File uploaded successfully');
      console.log('Filename:', fileUploadResponse.filename);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
    setUploadingFile(false);
  };

  const handleQuerySubmit = async (e) => {
    e.preventDefault();
    if (!filename) {
      console.error('No file uploaded');
      return;
    }
    setLoading(true);
    try {
      setSubmittingQuery(true);
      const queryResponse = await queryProtocol(filename, query)
      setAnswer(queryResponse.answer);
      setSubmittingQuery(false);
    } catch (error) {
      console.error('Error submitting query:', error);
      setSubmittingQuery(false);
    }
    setSubmittedQuery(query);
    setQuery("");
    // to mimic loading on fast load
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      console.error('No file selected');
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    await handleFileUpload(formData);
    // to mimic loading on fast load
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  useEffect(() => {
    setQueryClassName(styles.query_changed);
    setTimeout(() => {
      setQueryClassName(styles.query);
    }, 2000);
  }, [submittingQuery]);

  return (
    <Spin tip="Loading" size="large" spinning={loading}>
      <div className={styles.protocol_qa}>
        <h1 className={styles.protocol_qa__title}>Protocol QA</h1>

        <div className={styles.section}>
          <h2 className={styles.section__title}>Upload PDF</h2>
          <form onSubmit={handleFormSubmit} className={styles.protocol_qa__upload_file_form}>
            <label className={styles.file_label}>
              <input type="file" accept="application/pdf" onChange={handleFileChange} className={styles.protocol_qa__file} />
              Choose File
            </label>
            <span className={styles.file_name}>{file ? file.name : 'No file chosen'}</span>
            <button type="submit" className={styles.protocol_qa__submit}>
              {uploadingFile ? 'Uploading...' : 'Upload PDF'}
            </button>
          </form>
        </div>

        <div className={styles.section}>
          <h2 className={styles.section__title}>Submit Query</h2>
          <form onSubmit={handleQuerySubmit} className={styles.protocol_qa__query_form}>
            {submittedQuery !== null && <h4 className={queryClassName}>Query: {submittedQuery}</h4>}
            <input type="text" placeholder="Enter your query" value={query} onChange={handleQueryChange} className={styles.protocol_qa__input} />
            <button type="submit" className={styles.protocol_qa__submit}>
              {submittingQuery ? 'Submitting...' : 'Submit Query'}
            </button>
          </form>
        </div>

        <div className={styles.section}>
          <h2 className={styles.section__title}>Answer</h2>
          <div className={styles.protocol_qa__answer}>
            {answer}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default ProtocolQA;
