// Modules
import { useMemo, useEffect } from "react";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table";

// Styles
import styles from "./s.module.scss";

const columnHelper = createColumnHelper();

function SitePerfomanceTable(props) {
  const { data } = props;

  const columns = [
    columnHelper.accessor("site_id", {
      cell: ({ getValue, row }) => getValue(),
      header: "Side Id",
    }),
    columnHelper.accessor("name", {
      header: "Site Name",
    }),
    columnHelper.accessor("rand_to_date", {
      header: "Rand. To Date",
    }),
    columnHelper.accessor("ongoing_screening", {
      cell: ({ row, getValue }) => getValue(),
      header: "Ongoing Screening",
    }),
    columnHelper.accessor("total_expected_rand", {
      cell: ({ row, getValue }) => getValue(),
      header: "Total Expected Rand.",
    }),
    columnHelper.accessor("rand_est_fq", {
      cell: ({ row, getValue }) => getValue(),
      header: "Rand Est. in FQ",
    }),
  ];

  const table = useReactTable({
    data: data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const size = useMemo(() => data.length, [data.length]);
  useEffect(() => {
    table.setPageSize(size);
  }, [size, table]);

  return (
    <table className={styles["dashboard-table"]}>
      <thead className={styles["dashboard-table__header"]}>
        {table.getHeaderGroups().map((headerGroup, idx) => (
          <tr key={idx}>
            {headerGroup.headers.map((header, idx) => (
              <th
                key={idx}
                style={{ width: header.getSize() }}
                className={styles["dashboard-table__header__cell"]}
              >
                {header.isPlaceholder ? null : (
                  <div
                    {...{
                      className: header.column.getCanSort()
                        ? "cursor-pointer select-none"
                        : "",
                      onClick: header.column.getToggleSortingHandler(),
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className={styles["dashboard-table__body"]}>
        {table.getRowModel().rows.map((row, idx) => (
          <tr key={idx}>
            {row.getVisibleCells().map((cell, idx) => (
              <td key={idx}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

SitePerfomanceTable.propTypes = {};

SitePerfomanceTable.defaultProps = {};

export default SitePerfomanceTable;
