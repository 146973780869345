// Modules
import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

function ProtectedRoute(props) {
  const { isAllowed, redirectPath, withHistory, children } = props;
  const location = useLocation();

  if (withHistory) {
    localStorage.setItem("prevPage", location.pathname);
  }

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
}

export default ProtectedRoute;
