// Modules
import React from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";

// Assets
import MapImg from "assets/images/map.png";

import styles from "./s.module.scss";

function Welcome() {
  return (
    <div className={styles.welcome}>
      <div className={styles.container}>
        <img alt="Map" src={MapImg} />
        <Grid item md={6} xs={8}>
          <h2 className={styles.welcome__title}>
            We have gathered data from 200 Sites across 30 countries. SiteAI is
            ready to start the site selection and recommendation process.
          </h2>
        </Grid>
        <Link to="/dashboard-selection" className={styles.welcome__btn}>
          run site selection
        </Link>
      </div>
    </div>
  );
}

export default Welcome;
