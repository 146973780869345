// Modules
import { useEffect, useState, useCallback, useMemo } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table";
// import Slider from '@mui/material/Slider';
import { formatSitesData } from "helpers";
import qs from "qs";
import get from "lodash/get";
import { Link } from "react-router-dom";

// API
import { updateSiteById, loadSites } from "api";

// Components
import Checkbox from "components/Checkbox";
import Loader from "components/Loader";

// Context
import { useSiteSelectionContext } from "context/SiteSelectionContext";

// Context
import { SITES_LABELS } from "constants/sites";

// Styles
import styles from "./s.module.scss";
import { isEmpty } from "lodash";
import { useStore } from 'index';

const columnHelper = createColumnHelper();

function Dashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [user] = useStore(state => [state.user]);

  const userRole = get(user, "role");
  const isAdminUser = get(userRole, "type") === "super_admin";

  const { setActivatedSites, activatedSites, addedSites } =
    useSiteSelectionContext();

  const updateSite = useCallback(
    async (id, data) => {
      setIsLoading(true);
      try {
        const res = await updateSiteById(id, data);

        const index = activatedSites.findIndex((site) => site.id === id); // 👉️  0
        const temp = activatedSites;
        temp[index] = {
          ...res?.data?.attributes,
          id,
        };

        setActivatedSites(temp);

        if (res?.error) {
          throw res?.error;
        } else {
          // set the token
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [activatedSites, setActivatedSites]
  );

  const query = qs.stringify(
    {
      sort: ["activated:desc", "site_id:asc"],
    },
    {
      encodeValuesOnly: true,
    }
  );

  const fetchSites = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await loadSites(query);

      if (data?.error) {
        throw data?.error;
      } else {
        setActivatedSites(formatSitesData(data?.data));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [query, setActivatedSites]);
  const columns = [
    columnHelper.accessor("activated", {
      cell: ({ getValue, row }) => (
        <Checkbox
          checkboxClassName={styles["checkbox--activated"]}
          checked={row.original.activated}
          readOnly
        />
      ),
      header: SITES_LABELS.activated,
    }),
    columnHelper.accessor("site_id", {
      header: SITES_LABELS.id,
    }),
    columnHelper.accessor("name", {
      header: SITES_LABELS.name,
    }),
    columnHelper.accessor("items", {
      cell: ({ row, getValue }) => {
        const site = row.original;
        const currentSite =
          activatedSites[
            activatedSites?.findIndex((site) => site.id === row.original?.id)
          ];

        return (
          <>
            <Checkbox
              checked={currentSite?.budget}
              label="Budget"
              setLoading={setIsLoading}
              onChange={(e) =>
                isAdminUser
                  ? updateSite(site?.id, { budget: !currentSite?.budget })
                  : null
              }
            />
            <Checkbox
              checked={currentSite?.contract}
              label="Contract"
              setLoading={setIsLoading}
              onChange={(e) =>
                isAdminUser
                  ? updateSite(site?.id, { contract: !currentSite?.contract })
                  : null
              }
            />

            <Checkbox
              checked={currentSite?.regulatory}
              label="Regulatory"
              setLoading={setIsLoading}
              onChange={(e) =>
                isAdminUser
                  ? updateSite(site?.id, {
                      regulatory: !currentSite?.regulatory,
                    })
                  : null
              }
            />
            <Checkbox
              checked={currentSite?.irb}
              label="IRB"
              setLoading={setIsLoading}
              onChange={(e) =>
                isAdminUser
                  ? updateSite(site?.id, { irb: !currentSite?.irb })
                  : null
              }
            />
          </>
        );
      },
      header: "Remaining Items",
    }),
  ];

  const data = useMemo(
    () =>
      isEmpty(addedSites)
        ? activatedSites.filter((site) => site.activated)
        : activatedSites.filter((site) => addedSites[site.id]),

    [activatedSites, addedSites]
  );

  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    // useBlockLayout,
  });

  useEffect(() => {
    fetchSites();
  }, [fetchSites]);

  const size = data.length;
  useEffect(() => {
    table.setPageSize(size);
  }, [size, table]);

  const siteDashboardLink = `/dashboard`;
  return (
    <div className={styles.dashboard}>
      <div className={styles.container}>
        <h2 className={styles.dashboard__title}>Activation Dashboard </h2>
        <table className={styles["dashboard-table"]}>
          <thead className={styles["dashboard-table__header"]}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    style={{ width: header.getSize() }}
                    className={styles["dashboard-table__header__cell"]}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? "cursor-pointer select-none"
                            : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: " 🔼",
                          desc: " 🔽",
                        }[header.column.getIsSorted()] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className={styles["dashboard-table__body"]}>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {isLoading && <Loader withOverlay />}
        <div className={styles["dashboard__btn-container"]}>
          <Link to={siteDashboardLink} className={styles.dashboard__btn}>
            Enrollment Dashboard
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
