import React, { useState, useEffect } from 'react';
import { Typography, Button, Box } from '@mui/material';
import { Services } from './Services';
import { Footer } from './Footer';

export const MainContent = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            position: 'relative',
            boxSizing: 'border-box',
            top: '20px',
            gap: '20px'
        }}
    >
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                maxWidth: '90%'
            }}
            >
            <Box
                component="img"
                src="/images/clinical_background.webp" // Replace with your image path
                alt="Descriptive alt text"
                sx={{
                    maxWidth: '90%',
                    height: 'auto',
                }}
            />
            {windowWidth > 700 && <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '10px',
                    backgroundColor: 'black',
                    color: 'white',
                    padding: '15px',
                    position: 'absolute',
                    left: '-20px',
                    top: '140px'
                }}
            >
                <Typography variant="h5" sx={{ fontSize: { xs: '16px', sm: '24px' } }}>
                    {'INTELLIGENT PROTOCOL DESIGN,'}
                    <br />
                    {'SITE SELECTION AND ENROLLMENT OPTIMIZATION'}
                </Typography>
                <Button variant="contained" 
                    sx={{ backgroundColor: 'yellow', color: 'black', fontSize: { xs: '16px', sm: '24px' } }}
                    onClick={(event) => {
                        event.preventDefault();
                        window.location.href = `mailto:info@siteai.io`;
                    }}
                    >
                    Contact Us
                </Button>
            </Box>}
        </Box>
        {windowWidth <= 700 && <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '20px',
                    backgroundColor: 'black',
                    color: 'white',
                    textAlign: 'center',
                    padding: '15px',
                    maxWidth: '90%'
                }}
            >
                <Typography variant="h5" sx={{ 
                        fontSize: { xs: '16px', sm: '24px' } }
                    }>
                    {'INTELLIGENT PROTOCOL DESIGN,'}
                    <br />
                    {'SITE SELECTION AND ENROLLMENT OPTIMIZATION'}
                </Typography>
                <Button variant="contained" 
                        sx={{ backgroundColor: 'yellow', color: 'black', fontSize: { xs: '16px', sm: '24px' } }}
                        onClick={(event) => {
                            event.preventDefault();
                            window.location.href = `mailto:info@siteai.io`;
                        }}
                    >
                    Contact Us
                </Button>
            </Box>}
        <Services />
        <Footer />
    </Box>
}