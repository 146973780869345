// Modules
import React from 'react';
// import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import styles from './input.module.scss';

function Input(props) {
  const { className, classNameLabel, onChange, label, value, ...restProps } =
    props;

  const classNames = cx(styles.input, {
    [className]: !!className,
  });

  return (
    <div className={styles.input__container}>
      {label && <Typography component="p">{label}</Typography>}
      <input
        className={classNames}
        value={value}
        onChange={onChange}
        {...restProps}
      />
    </div>
  );
}

export default Input;
