// Modules
import React from 'react';

function IconCalendar() {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.25 11.375C19.25 11.1429 19.3422 10.9204 19.5063 10.7563C19.6704 10.5922 19.8929 10.5 20.125 10.5H21.875C22.1071 10.5 22.3296 10.5922 22.4937 10.7563C22.6578 10.9204 22.75 11.1429 22.75 11.375V13.125C22.75 13.3571 22.6578 13.5796 22.4937 13.7437C22.3296 13.9078 22.1071 14 21.875 14H20.125C19.8929 14 19.6704 13.9078 19.5063 13.7437C19.3422 13.5796 19.25 13.3571 19.25 13.125V11.375Z'
        fill='white'
      />
      <path
        d='M6.125 0C6.35706 0 6.57962 0.0921872 6.74372 0.256282C6.90781 0.420376 7 0.642936 7 0.875V1.75H21V0.875C21 0.642936 21.0922 0.420376 21.2563 0.256282C21.4204 0.0921872 21.6429 0 21.875 0C22.1071 0 22.3296 0.0921872 22.4937 0.256282C22.6578 0.420376 22.75 0.642936 22.75 0.875V1.75H24.5C25.4283 1.75 26.3185 2.11875 26.9749 2.77513C27.6313 3.4315 28 4.32174 28 5.25V24.5C28 25.4283 27.6313 26.3185 26.9749 26.9749C26.3185 27.6313 25.4283 28 24.5 28H3.5C2.57174 28 1.6815 27.6313 1.02513 26.9749C0.368749 26.3185 0 25.4283 0 24.5V5.25C0 4.32174 0.368749 3.4315 1.02513 2.77513C1.6815 2.11875 2.57174 1.75 3.5 1.75H5.25V0.875C5.25 0.642936 5.34219 0.420376 5.50628 0.256282C5.67038 0.0921872 5.89294 0 6.125 0V0ZM1.75 7V24.5C1.75 24.9641 1.93437 25.4092 2.26256 25.7374C2.59075 26.0656 3.03587 26.25 3.5 26.25H24.5C24.9641 26.25 25.4092 26.0656 25.7374 25.7374C26.0656 25.4092 26.25 24.9641 26.25 24.5V7H1.75Z'
        fill='white'
      />
    </svg>
  );
}

export default IconCalendar;
