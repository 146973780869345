// Modules
import { Tabs, Button } from 'antd'
import {useState, forwardRef, useImperativeHandle} from "react";
import Commonalities from './Commonalities';
import ProtocolCriteria from './ProtocolCriteria';
import ProtocolSites from './ProtocolSites';
import ProtocolTrial from './ProtocolTrial';

// Assets
import styles from "./s.module.scss";

function ProtocolTabs(props, ref) {
  useImperativeHandle(ref, () => {
    return {
      resetTab () {
        goToTrial();
      }
    };
  }, []);

  const [key, setKey] = useState('1');

  const onChange = (activeKey) => setKey(activeKey);
  const goToTrial = () => setKey("1");
  const goToCommonalities = () => setKey("2");

  const items = [
    {
      key: '1',
      label: `Your Clinical Trial`,
      children: <ProtocolTrial goToCommonalities={goToCommonalities} />,
    },
    {
      key: '2',
      label: `Commonalities`,
      children: <Commonalities />,
    },
    {
      key: '3',
      label: `Study Sites`,
      children: <ProtocolSites />,
    },
    {
      key: '4',
      label: `Inc/Exc Criteria`,
      children: <ProtocolCriteria />,
    }
  ];

  return (
    <div>
    <Tabs defaultActiveKey="1" activeKey={key} items={items} onChange={onChange} className={styles.container} />
    <div className={styles.button_container}>
    <Button className={styles.submit_button} onClick={() => { window.location.href='/dashboard-selection'; }}>Next: Site Selection</Button>
    </div>
    </div>
  );
}

export default forwardRef(ProtocolTabs);
