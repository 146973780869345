// Modules
import React from 'react';

function IconCalendarChecked() {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.9944 12.5055C19.0759 12.5868 19.1405 12.6833 19.1846 12.7896C19.2287 12.8959 19.2514 13.0099 19.2514 13.125C19.2514 13.2401 19.2287 13.354 19.1846 13.4603C19.1405 13.5666 19.0759 13.6632 18.9944 13.7445L13.7444 18.9945C13.6631 19.076 13.5665 19.1406 13.4602 19.1847C13.3539 19.2288 13.24 19.2515 13.1249 19.2515C13.0098 19.2515 12.8958 19.2288 12.7895 19.1847C12.6832 19.1406 12.5867 19.076 12.5054 18.9945L9.88038 16.3695C9.79903 16.2881 9.7345 16.1915 9.69047 16.0852C9.64644 15.979 9.62378 15.865 9.62378 15.75C9.62378 15.6349 9.64644 15.521 9.69047 15.4147C9.7345 15.3084 9.79903 15.2118 9.88038 15.1305C10.0447 14.9662 10.2675 14.8739 10.4999 14.8739C10.6149 14.8739 10.7289 14.8965 10.8352 14.9406C10.9415 14.9846 11.038 15.0491 11.1194 15.1305L13.1249 17.1377L17.7554 12.5055C17.8367 12.424 17.9332 12.3593 18.0395 12.3152C18.1458 12.2711 18.2598 12.2484 18.3749 12.2484C18.49 12.2484 18.6039 12.2711 18.7102 12.3152C18.8165 12.3593 18.9131 12.424 18.9944 12.5055Z'
        fill='#3D4046'
      />
      <path
        d='M6.125 0C6.35706 0 6.57962 0.0921873 6.74372 0.256282C6.90781 0.420376 7 0.642936 7 0.875V1.75H21V0.875C21 0.642936 21.0922 0.420376 21.2563 0.256282C21.4204 0.0921873 21.6429 0 21.875 0C22.1071 0 22.3296 0.0921873 22.4937 0.256282C22.6578 0.420376 22.75 0.642936 22.75 0.875V1.75H24.5C25.4283 1.75 26.3185 2.11875 26.9749 2.77513C27.6313 3.4315 28 4.32174 28 5.25V24.5C28 25.4283 27.6313 26.3185 26.9749 26.9749C26.3185 27.6313 25.4283 28 24.5 28H3.5C2.57174 28 1.6815 27.6313 1.02513 26.9749C0.368749 26.3185 0 25.4283 0 24.5V5.25C0 4.32174 0.368749 3.4315 1.02513 2.77513C1.6815 2.11875 2.57174 1.75 3.5 1.75H5.25V0.875C5.25 0.642936 5.34219 0.420376 5.50628 0.256282C5.67038 0.0921873 5.89294 0 6.125 0V0ZM1.75 7V24.5C1.75 24.9641 1.93437 25.4092 2.26256 25.7374C2.59075 26.0656 3.03587 26.25 3.5 26.25H24.5C24.9641 26.25 25.4092 26.0656 25.7374 25.7374C26.0656 25.4092 26.25 24.9641 26.25 24.5V7H1.75Z'
        fill='#3D4046'
      />
    </svg>
  );
}

export default IconCalendarChecked;
