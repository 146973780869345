import { useLocation, useNavigate } from "react-router-dom";
import { Container, Grid, TextField, Alert, Paper, Typography, Button } from "@mui/material";
import { useState } from "react";
import { BACKEND_API } from "constants";
import { message } from "antd";

function ResetPassword() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const [password, setPassword] = useState(null);
    const [passwordConfirmation, setPasswordConfirmation] = useState(null);

    const handleChangePassword = (event) => {
        setPassword(event.target.value);
    }

    const handleChangePasswordConfirmation = (event) => {
        setPasswordConfirmation(event.target.value);
    }


    const onFinish = async () => {
        try {
            if (!params || !params.get('code')) {
                throw new Error("Your reset password link is broken");
            }

            if (!password || !passwordConfirmation) {
                throw new Error("Please fill password fields");
            }

            const response = await fetch(`${BACKEND_API}/auth/reset-password`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    code: params.get("code"),
                    password: password, 
                    passwordConfirmation: passwordConfirmation
                }),
            });
            
            const data = await response.json();
            if (response.status != 200) {
                throw new Error(data.error.message);
            }
            
            message.success("Your password updated successfully, you can login with new password");
            navigate("/signin", { replace: true });
        } catch (err) {
            message.error(err.message);
        }
    }

    return (
    <Container maxWidth="lg">
        <Grid container spacing={2} alignItems="center" justifyContent="space-around">
            <Grid item xs={12} md={4} >
                <Paper elevation={0} sx={{ padding: 6 }}>
                    <Typography variant="h5" gutterBottom>
                        Reset your password
                    </Typography>
                    <TextField onChange={handleChangePassword} required label="Password" variant="outlined" fullWidth margin="dense" size="small" type="password" />
                    <TextField onChange={handleChangePasswordConfirmation} required label="Password Confirmation" variant="outlined" fullWidth margin="dense" size="small" type="password" />
                    <Button variant="contained" color="primary" fullWidth onClick={onFinish} >
                        Update Password
                    </Button>
                </Paper>
            </Grid>
        </Grid>
    </Container>);
}

export default ResetPassword;