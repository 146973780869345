// Modules
import { useState } from 'react';


// Context
import { SiteSelectionContext } from 'context/SiteSelectionContext';

const SiteProvider = SiteSelectionContext.Provider;

function SiteRankProvider(props) {
  const { children } = props;

  const [sites, setSites] = useState([]);
  const [pagination, setPagination] = useState(20);


  const [addedSites, setAddedSites] = useState({});
  const [activatedSites, setActivatedSites] = useState([]);
  const [dashboard, setDashboard] = useState({});
  const [weeksData, setWeeksData] = useState([]);


  const value = {
    activatedSites,
    sites,
    pagination,
    setSites,
    setPagination,
    addedSites,
    setAddedSites,
    setActivatedSites,
    dashboard,
    setDashboard,
    weeksData,
    setWeeksData,
  };
  return <SiteProvider value={value}>{children}</SiteProvider>;
}

SiteRankProvider.propTypes = {};

SiteRankProvider.defaultProps = {};

export default SiteRankProvider;
