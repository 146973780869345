// Modules
import { Form, Button, DatePicker, AutoComplete, Select  } from 'antd';
import {useStore} from 'index';
import { useState } from "react";
import qs from "qs";
import debounce from 'lodash/debounce';
import * as dayjs from 'dayjs'

// Assets
import styles from "./s.module.scss";

// API
import { searchCondition, searchCity, searchCountry, getCommonalities } from "api";

const toOption = (value) => ({value});
const toOptionList = (value) => [toOption(value)];

function ProtocolTrial({goToCommonalities}) {
  const [trialFilter, phases, setCommonalities, setLoading] = useStore((state) => [state.trialFilter, state.phases, state.setCommonalities, state.setLoading]);
  const [selectedConditions, setSelectedConditions] = useState(toOptionList(trialFilter.condition));
  const [selectedPhases, setSelectedPhases] = useState(toOptionList(trialFilter.phase));
  const [city, setCity] = useState(trialFilter.city);
  const [cityOptions, setCityOptions] = useState(toOptionList(trialFilter.city));
  const [country, setCountry] = useState(trialFilter.country);
  const [countryOptions, setCountryOptions] = useState(toOptionList(trialFilter.country));
  const [date, setDate] = useState(dayjs(trialFilter.date_datetime));

  const phaseOptions = phases.map(toOption);

  const onConditionSearch = async (value) => {
    setLoading(true);
    const query = qs.stringify(
      {
        partial_string: value
      },
      {
        encodeValuesOnly: true,
      }
    );
    const data = await searchCondition(query);
    setSelectedConditions(data.map(toOption));
    // to mimic loading on fast load
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }
  const onConditionSelect = (_, opt) => {
    setSelectedConditions(values => {
      if (values.includes(opt)) return values;
      return values.concat(opt);
    });
  }
  const onConditionDeselect = (_, opt) => {
    setSelectedConditions(values => values.filter(val => val !== opt));
  }
  const onPhaseChange = (_, opt) => setSelectedPhases(opt);
  const onCitySelect = (value) => setCity(value);
  const onCitySearch = async (value) => {
    setLoading(true);
    const query = qs.stringify(
      {
        partial_string: value
      },
      {
        encodeValuesOnly: true,
      }
    );
    const data = await searchCity(query);
    setCityOptions(data.map(toOption));
    // to mimic loading on fast load
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }
  
  const onCountrySelect = (value) => setCountry(value);
  const onCountrySearch = async (value) => {
    setLoading(true);
    const query = qs.stringify(
      {
        partial_string: value
      },
      {
        encodeValuesOnly: true,
      }
    );
    const data = await searchCountry(query);
    setCountryOptions(data.map(toOption));
    // to mimic loading on fast load
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  const onDateChange = (dateValue) => setDate(dateValue);
  const onSubmit = async (values) => {
    setLoading(true);
    const body = {...values, date: values.date.format('DD/MM/YYYY')}
    const data = await getCommonalities(body);
    setCommonalities(data);
    goToCommonalities();
    // to mimic loading on fast load
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  return (
    <div className={styles.container}>
      <Form onFinish={onSubmit} layout="vertical" className={styles.form} initialValues={{
        ...trialFilter,
        condition: trialFilter.condition,
        phase: trialFilter.phase,
        date
      }}>
        <Form.Item name="condition" label={<p className={styles.bold_label}>Condition</p>}>
        <Select
          mode="multiple"
          allowClear
          value={selectedConditions}
          options={selectedConditions}
          onSearch={onConditionSearch}
          onSelect={onConditionSelect}
          onDeselect={onConditionDeselect}
        />
        </Form.Item>
        <Form.Item name="phase" label={<p className={styles.bold_label}>Phase</p>}>
          <Select
            mode="multiple"
            allowClear
            value={selectedPhases}
            options={phaseOptions}
            onChange={onPhaseChange}
          />
        </Form.Item>
        <p className={styles.bold_label}>Location</p>
        <Form.Item name="city" label="City">
        <AutoComplete
          value={city}
          options={cityOptions}
          onSelect={onCitySelect}
          onSearch={debounce(onCitySearch, 300)}
          placeholder="Type in a city"
        />
        </Form.Item>
        <Form.Item name="country" label="Country">
        <AutoComplete
          value={country}
          options={countryOptions}
          onSelect={onCountrySelect}
          onSearch={debounce(onCountrySearch, 300)}
        />
        </Form.Item>
        <Form.Item name="date" label={<p className={styles.bold_label}>Date</p>}>
        <DatePicker className={styles.datepicker} value={date} onChange={onDateChange}/>
        </Form.Item>
        <Form.Item>
          <Button className={styles.submit_button} htmlType="submit">
            Confirm
          </Button>
        </Form.Item>
      </Form>
    </div>
);
}

export default ProtocolTrial;
