import PropTypes from "prop-types";
import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

function BasicCard(props) {
  const { children, contentClassname, variant, icon, title, subtitle } = props;
  const sx = !!variant
    ? {
        display: "flex",
        flexDirection: "column",
        color: "#fff",
        border: "1px solid #EB7283",
        borderRadius: "20px",
        padding: "4px",
        background: "#fff",
        boxShadow: "none",
      }
    : {
        display: "flex",
        flexDirection: "column",
        color: "#3D4046",
        border: "1px solid #E7E6E6",
        borderRadius: "20px",
        padding: "4px",
        background: "#fff",
        boxShadow: "none",
      };

  return (
    <Card sx={sx}>
      <CardContent
        className={contentClassname}
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          rowGap: "8px",
          background: variant ? "#EB7283" : "#fff",
          borderRadius: "15px",
          boxShadow: !variant
            ? "inset 0px 4px 10px rgba(43, 36, 36, 0.15)"
            : "none",
          padding: "30px 20px",
        }}
      >
        {children || (
          <>
            {" "}
            {!!icon && icon}
            <Typography sx={{ fontSize: 16, margin: 0 }} gutterBottom>
              {!!title && title}
            </Typography>
            <Typography
              sx={{ fontSize: 25, fontWeight: 600 }}
              variant="h5"
              component="div"
            >
              {!!subtitle && subtitle}
            </Typography>
          </>
        )}
      </CardContent>
    </Card>
  );
}

BasicCard.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

BasicCard.defaultProps = {
  subtitle: "0",
  title: "0",
};

export default BasicCard;
