// icon:chevron-left | CSS Icons https://css.gg/ | Astrit
import * as React from "react";

function IconChevronLeft(props) {
  return (
    <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
      <path
        fill="currentColor"
        d="M16.243 6.343L14.828 4.93 7.758 12l7.07 7.071 1.415-1.414L10.586 12l5.657-5.657z"
      />
    </svg>
  );
}

export default IconChevronLeft;