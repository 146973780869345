// Modules
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import cx from 'classnames';

// Styles
import s from './s.module.scss';

// const styles = ({ size, withOverlay }) => css`
// width: ${size}px
// height: ${size}px

// &::before {
//   background: ${withOverlay ? primary : 'white'}
//   width: ${size - 7}px
//   height: ${size - 7}px
// }
// `;

const primary = 'rgba(42, 57, 96, 0.21)';

let Spinner = styled.div`
  ${({ size, withOverlay }) => `
  width: ${size}px;
  height: ${size}px;
  // &::before {
  //   background: ${withOverlay ? primary : 'white'};
  //   width: ${size - 7}px;
  //   height: ${size - 7}px;
  // }`}
`;

function Loader(props) {
  const { className, withOverlay, size, ...restProps } = props;

  const classNames = cx(s.loader, {
    [className]: !!className,
  });

  const spinner = (
    <Spinner
      className={classNames}
      // css={css`
      //   width: ${size}px
      //   height: ${size}px

      //   &:before {
      //     background: ${withOverlay ? primary : 'white'}
      //     width: ${size - 7}px
      //     height: ${size - 7}px
      //   }`}

      // css={styles({ ...props })}
      size={size}
      {...restProps}
    />
  );

  if (!withOverlay) return spinner;

  return <div className={s.loader__overlay}>{spinner}</div>;
}

Loader.propTypes = {
  size: PropTypes.number,
};

Loader.defaultProps = {
  size: 100,
};

export default Loader;
